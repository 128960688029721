import Service from "../Models/service";
import { ServicesAvailable } from "../Types/servicesAvailable";
import tenantCommunicationService from "./tenantCommunicationService";
import tenantRegistrationApi from "./tenantRegistrationApi";

const services = {
  tenantCommunicationService,
  tenantRegistrationApi,
};

const getService = (serviceName: ServicesAvailable): Service =>
  services[serviceName];

export default getService;
