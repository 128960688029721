import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Box, Button, CardContent, Grid, TextField, Typography } from "@mui/material";

import { isEmptyOrWhiteSpace } from "../../../Helpers/stringHelper";
import { validatePin } from "../../../Helpers/validationHelper";
import { setSnackbarSettings } from "../../../Store/Slices/snackbarSlice";
import {
  rdx_clearStepErrorMessage,
  rdx_getIsLoading,
  rdx_getStepValidationResult,
  rdx_loadCheckPin,
} from "../../../Store/Slices/stepManagerSlice";
import { rdx_getProductName } from "../../../Store/Slices/tenantRegistationSlice";
import AttemptsRemainingAlert from "../../HelperComponents/AttempsRemainingAlert/AttemptsRemainingAlert";

import "../../Main/Main.scss";
import Loading from "../../Loading/Loading";

const AccountPin = () => {
  // From Redux
  const dispatch = useDispatch();
  const rdx_stepValidationResult = useSelector(
    rdx_getStepValidationResult("AccountPin")
  );
  const rdx_IsLoading = useSelector(rdx_getIsLoading);
  // Local State
  const [pin, setPin] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const productName = useSelector(rdx_getProductName);

  useEffect(() => {
    if (
      !rdx_stepValidationResult.success &&
      !isEmptyOrWhiteSpace(rdx_stepValidationResult.message)
    ) {
      let [isValid, _] = validatePin(pin);

      if (isValid) {
        dispatch(
          setSnackbarSettings({
            open: true,
            severity: "error",
            message: "Failed Attempt",
          })
        );
        setTimeout(() => {
          dispatch(rdx_clearStepErrorMessage());
        }, 1500);
      }

      setError(true);
      setErrorMessage(rdx_stepValidationResult.message);
    }
  }, [rdx_stepValidationResult, pin, dispatch]);

  const handlePinValueChanged = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    let pin = e.currentTarget.value;
    let [isValid, validationMessage] = validatePin(pin);

    setPin(pin);
    setError(!isValid);
    setErrorMessage(isValid ? "" : validationMessage);
  };

  const nextButtonClick = () => {
    let [isValid, _] = validatePin(pin);

    //
    // If there isn't a current error or the pin is currently valid (meaning the error came back from the service check.)
    if (!error || isValid) {
      dispatch(rdx_loadCheckPin(pin));
    }
  };

  return (
    <>
      <CardContent>
        <Typography m={3} variant="h6" gutterBottom>
          Enter your Account PIN
        </Typography>
        {rdx_IsLoading && <Loading />}
        {!rdx_IsLoading && (
          <>
            <TextField
              id="pin-input"
              label="PIN"
              variant="outlined"
              className="textbox-width"
              inputProps={{ maxLength: 4 }}
              autoComplete="off"
              onChange={handlePinValueChanged}
              value={pin}
              error={error}
              helperText={errorMessage}
            />
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center">
              <Alert severity="info">
                Your account PIN can be found in an email you received from {productName}.
              </Alert>
            </Grid>
            <Box textAlign="center" my={3}>
              <Button
                variant="contained"
                onClick={nextButtonClick}
                color="secondary"
                size="large"
              >
                Next
              </Button>
            </Box>
          </>
        )}
        <AttemptsRemainingAlert />
      </CardContent>
    </>
  );
};

export default AccountPin;
