import React, { FunctionComponent, useEffect } from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";

import {
  rdx_getIsLoading,
  rdx_getLastRegistrationStepName,
  rdx_getLoadErrorMessage,
  rdx_getRegistrationComplete,
} from "./Store/Slices/tenantRegistationSlice";

import Main from "./Components/Main/Main";
import Loading from "./Components/Loading/Loading";
import { isEmptyOrWhiteSpace } from "./Helpers/stringHelper";
import { rdx_getFailedAttemptCount } from "./Store/Slices/stepManagerSlice";
import { rdx_getIsMaintenanceWindow } from "./Store/Slices/maintenanceSlice";
import MaintenanceTakeDown from "./Components/Maintenance/MaintenanceTakeDown";

const CreateRoutes: FunctionComponent = (): JSX.Element => {
  // From Redux
  const rdx_failedAttemptCount = useSelector(rdx_getFailedAttemptCount);
  const rdx_isMaintenanceWindow = useSelector(rdx_getIsMaintenanceWindow);
  const rdx_isLoading = useSelector(rdx_getIsLoading);
  const rdx_lastRegistrationStepName = useSelector(
    rdx_getLastRegistrationStepName
  );
  const rdx_registrationComplete = useSelector(rdx_getRegistrationComplete);
  const rdx_loadErrorMessage = useSelector(rdx_getLoadErrorMessage);

  return (
    <Routes>
      <Route
        path="/Setup/Welcome/*"
        element={
          rdx_isMaintenanceWindow ? (
            <MaintenanceTakeDown />
          ) : !isEmptyOrWhiteSpace(rdx_loadErrorMessage) ? (
            <Main componentName="ContactCustomerService" />
          ) : rdx_isLoading ? (
            <Loading />
          ) : rdx_registrationComplete ? (
            <Main componentName={rdx_lastRegistrationStepName} />
          ) : rdx_failedAttemptCount < 3 ? (
            <Main componentName="Stepper" />
          ) : (
            <Main componentName="ContactCustomerService" />
          )
        }
      />
      <Route
        path="/"
        element={<Loading />}
      />
      <Route
        path="/Error/ContactCustomerService"
        element={<Main componentName="ContactCustomerService" />}
      />
      <Route
        path="*"
        element={<Main componentName="ContactCustomerService" />}
      />
    </Routes>
  );
};

export default CreateRoutes;
