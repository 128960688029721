import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  CardContent,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

import getService from "../../../Services/serviceFactory";
import { setSnackbarSettings } from "../../../Store/Slices/snackbarSlice";
import {
  rdx_clearStepErrorMessage,
  rdx_getIsLoading,
  rdx_getStepValidationResult,
  rdx_loadCheckCommunity,
} from "../../../Store/Slices/stepManagerSlice";
import { rdx_getNewToken } from "../../../Store/Slices/tenantRegistationSlice";
import AttemptsRemainingAlert from "../../HelperComponents/AttempsRemainingAlert/AttemptsRemainingAlert";
import Loading from "../../Loading/Loading";

export default function SelectedCommunity() {
  // From Redux
  const dispatch = useDispatch();
  const rdx_newToken = useSelector(rdx_getNewToken);
  const rdx_stepValidationResult = useSelector(
    rdx_getStepValidationResult("SelectCommunity")
  );
  const rdx_IsLoading = useSelector(rdx_getIsLoading);

  // Local State
  const [selectedProperty, setSeletedProperty] = useState<number>();
  const [communityList, setCommunityList] = useState<string[]>([]);

  useEffect(() => {
    async function fetchData() {
      var service = getService("tenantRegistrationApi");

      const request = await service.post(
        `${service.baseUrl}/Registration/GetCommunityList`,
        { token: rdx_newToken },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${service.authToken}`,
            AppName: "TenantRegistration",
          },
        }
      );

      setCommunityList(request.data);

      return request;
    }

    fetchData();
  }, [rdx_newToken]);

  useEffect(() => {
    if (
      !rdx_stepValidationResult.success &&
      rdx_stepValidationResult.message !== ""
    ) {
      dispatch(
        setSnackbarSettings({
          open: true,
          severity: "error",
          message: "Failed Attempt",
        })
      );
      setTimeout(() => {
        dispatch(rdx_clearStepErrorMessage());
      }, 1500);
    }
  }, [rdx_stepValidationResult, dispatch]);

  const handleListItemClick = (index: number) => {
    setSeletedProperty(index);
  };

  const nextButtonClick = () => {
    dispatch(rdx_loadCheckCommunity(communityList[selectedProperty]));
  };

  return (
    <CardContent className="select-community-card-content">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Typography m={3} variant="h6" gutterBottom>
          Select your Community
        </Typography>
      </Grid>
      {rdx_IsLoading && <Loading />}
      {!rdx_IsLoading && (
        <>
          <Grid justifyContent="center" alignItems="center">
            <List
              component="nav"
              aria-label="main mailbox folders"
              className="select-community-list-item-btn"
            >
              {communityList.map((item, index) => (
                <ListItemButton
                  key={index}
                  selected={selectedProperty === index}
                  onClick={() => handleListItemClick(index)}
                >
                  <ListItemText primary={item} />
                </ListItemButton>
              ))}
            </List>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            mt={3}
            mb={3}
          >
            <Button
              variant="contained"
              onClick={nextButtonClick}
              color="secondary"
              size="large"
            >
              Next
            </Button>
          </Grid>
        </>
      )}

      <AttemptsRemainingAlert />
    </CardContent>
  );
}
