import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Alert, Grid } from "@mui/material";

import { rdx_getAttemptsRemaining } from "../../../Store/Slices/stepManagerSlice";

const AttemptsRemainingAlert: FunctionComponent = (): JSX.Element => {
  // From Redux
  const rdx_attemptsRemaining = useSelector(rdx_getAttemptsRemaining);

  const severity =
    rdx_attemptsRemaining === 3
      ? "info"
      : rdx_attemptsRemaining === 2
      ? "warning"
      : "error";

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        my={2}
      >
        <Alert severity={severity} icon={false}>
          Remaining Attempts: <strong>{rdx_attemptsRemaining}</strong>
        </Alert>
      </Grid>
    </>
  );
};

export default AttemptsRemainingAlert;
