import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk, GetStateType } from "../../index";
import DetailedPaymentInfo from "../../Models/detailedPaymentInfo";
import PaymentRankOrder from "../../Models/paymentRankOrder";
import RemovePaymentInfoBody from "../../Models/ServiceBody/removePaymentInfoBody";
import SaveCardInfoBody from "../../Models/ServiceBody/saveCardInfoBody";
import SaveEFTInfoBody from "../../Models/ServiceBody/saveEFTInfoBody";
import SavePaymentRankBody from "../../Models/ServiceBody/savePaymentRankBody";
import TokenBody from "../../Models/ServiceBody/tokenBody";
import { getServiceCallBeganAction } from "../../Models/serviceRequest";
import ServiceSuccessPayload from "../../Models/serviceSuccessPayload";
import ServiceValidationResult from "../../Models/serviceValidationResult";
import PaymentSliceState from "../../Models/SliceState/paymentSliceState";
import ValidationResult from "../../Models/validationResult";
import { RootState } from "../reducer";

const slice = createSlice({
  name: "payment",
  initialState: {
    isLoading: false,
    isInitialized: false,
    detailedPaymentInfoList: [] as DetailedPaymentInfo[],
    lastResult: null as ValidationResult,
    needsRefresh: true,
  } as PaymentSliceState,
  reducers: {
    paymentCallOnStart: (draft) => {
      draft.isLoading = true;
    },
    getDetailedPaymentInfoOnSuccess: (
      draft,
      action: PayloadAction<
        ServiceSuccessPayload<DetailedPaymentInfo[], TokenBody>
      >
    ) => {
      draft.isLoading = false;
      draft.isInitialized = true;

      draft.detailedPaymentInfoList = action.payload.data.sort(
        (a, b) => a.rankOrder - b.rankOrder
      );
      draft.needsRefresh = false;
    },
    paymentCallOnSuccess: (
      draft,
      action: PayloadAction<ServiceSuccessPayload<ServiceValidationResult, any>>
    ) => {
      draft.isLoading = false;
      draft.lastResult = action.payload.data;
      draft.needsRefresh = true;
    },
    paymentCallOnError: (draft, action: PayloadAction<{ message: string }>) => {
      draft.isLoading = false;
      draft.lastResult = {
        success: false,
        message: action.payload.message,
        failedAttemptCount: 0,
      };
    },
    resetLastResult: (draft) => {
      draft.lastResult = null;
    },
  },
});

// selectors
export const rdx_getIsLoading = (state: RootState) =>
  state.entities.payment.isLoading;

export const rdx_getIsInitialized = (state: RootState) =>
  state.entities.payment.isInitialized;

export const rdx_getPaymentInfo = (state: RootState) =>
  state.entities.payment.detailedPaymentInfoList;

export const rdx_getLastResult = (state: RootState) =>
  state.entities.payment.lastResult;

export const rdx_getNeedsRefresh = (state: RootState) =>
  state.entities.payment.needsRefresh;

const {
  paymentCallOnStart,
  paymentCallOnError,
  getDetailedPaymentInfoOnSuccess,
  paymentCallOnSuccess,
  resetLastResult,
} = slice.actions;

const getDetailedPaymentInfoServiceCallBegan =
  getServiceCallBeganAction<TokenBody>();

export const rdx_loadDetailedPaymentInfo =
  (): AppThunk => (dispatch: AppDispatch, getState: GetStateType) => {
    let tenantRegistrationState = getState().entities.tenantRegistration;
    let token = tenantRegistrationState.newToken;

    dispatch(
      getDetailedPaymentInfoServiceCallBegan({
        serviceName: "tenantRegistrationApi",
        serviceActionPath: "/Payment/GetDetailedPaymentInfo",
        data: {
          token,
        },
        headers: {
          "Content-Type": "application/json",
          AppName: "TenantRegistration",
        },
        method: "post",
        onStart: paymentCallOnStart.type,
        onSuccess: getDetailedPaymentInfoOnSuccess.type,
        onError: paymentCallOnError.type,
      })
    );
  };

const removePaymentInfoServiceCallBegan =
  getServiceCallBeganAction<RemovePaymentInfoBody>();

export const rdx_removePaymentInfo =
  (transactionInformationID: number): AppThunk =>
  (dispatch: AppDispatch, getState: GetStateType) => {
    let tenantRegistrationState = getState().entities.tenantRegistration;
    let token = tenantRegistrationState.newToken;

    dispatch(
      removePaymentInfoServiceCallBegan({
        serviceName: "tenantRegistrationApi",
        serviceActionPath: "/Payment/RemovePaymentInfo",
        data: {
          token,
          transactionInformationID,
        },
        headers: {
          "Content-Type": "application/json",
          AppName: "TenantRegistration",
        },
        method: "post",
        onStart: paymentCallOnStart.type,
        onSuccess: paymentCallOnSuccess.type,
        onError: paymentCallOnError.type,
      })
    );
  };

const saveCardInfoServiceCallBegan =
  getServiceCallBeganAction<SaveCardInfoBody>();

export const rdx_saveCardInfo =
  (cardInfo: SaveCardInfoBody): AppThunk =>
  (dispatch: AppDispatch, getState: GetStateType) => {
    let tenantRegistrationState = getState().entities.tenantRegistration;
    let token = tenantRegistrationState.newToken;

    dispatch(
      saveCardInfoServiceCallBegan({
        serviceName: "tenantRegistrationApi",
        serviceActionPath: "/Payment/SaveCardInfo",
        data: {
          ...cardInfo,
          token,
        },
        headers: {
          "Content-Type": "application/json",
          AppName: "TenantRegistration",
        },
        method: "post",
        onStart: paymentCallOnStart.type,
        onSuccess: paymentCallOnSuccess.type,
        onError: paymentCallOnError.type,
      })
    );
  };

const saveEFTInfoServiceCallBegan =
  getServiceCallBeganAction<SaveEFTInfoBody>();

export const rdx_saveEFTInfo =
  (eftInfo: SaveEFTInfoBody): AppThunk =>
  (dispatch: AppDispatch, getState: GetStateType) => {
    let tenantRegistrationState = getState().entities.tenantRegistration;
    let token = tenantRegistrationState.newToken;

    dispatch(
      saveEFTInfoServiceCallBegan({
        serviceName: "tenantRegistrationApi",
        serviceActionPath: "/Payment/SaveEFTInfo",
        data: {
          ...eftInfo,
          token,
        },
        headers: {
          "Content-Type": "application/json",
          AppName: "TenantRegistration",
        },
        method: "post",
        onStart: paymentCallOnStart.type,
        onSuccess: paymentCallOnSuccess.type,
        onError: paymentCallOnError.type,
      })
    );
  };

const savePaymentRankServiceCallBegan =
  getServiceCallBeganAction<SavePaymentRankBody>();

export const rdx_reorderPaymentInfo =
  (paymentInfo: DetailedPaymentInfo[]): AppThunk =>
  (dispatch: AppDispatch, getState: GetStateType) => {
    let tenantRegistrationState = getState().entities.tenantRegistration;
    let token = tenantRegistrationState.newToken;

    const rankOrder = paymentInfo.map((value, index) => {
      return {
        transactionInformationID: value.transactionInformationID,
        rankOrder: index + 1,
      } as PaymentRankOrder;
    });

    dispatch(
      savePaymentRankServiceCallBegan({
        serviceName: "tenantRegistrationApi",
        serviceActionPath: "/Payment/SavePaymentRank",
        data: {
          rankOrder,
          token,
        },
        headers: {
          "Content-Type": "application/json",
          AppName: "TenantRegistration",
        },
        method: "post",
        onStart: null,
        onSuccess: null,
        onError: null,
      })
    );
  };

export const rdx_resetLastResult = (): AppThunk => (dispatch: AppDispatch) =>
  dispatch(resetLastResult());

export default slice.reducer;
