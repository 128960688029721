import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  validateEftRoutingNumber,
  nonAsciiCommasCheck,
} from "../../../Helpers/validationHelper";
import SaveEFTInfoBody from "../../../Models/ServiceBody/saveEFTInfoBody";
import {
  rdx_getPaymentInfo,
  rdx_saveEFTInfo,
} from "../../../Store/Slices/paymentSlice";
import { EFTAccountTypes } from "../../../Types/eftAccountTypes";
import "../../Main/Main.scss";

interface AddEFTPaymentFormProps {
  achPaymentEnabled: boolean;
}

const AddEFTPaymentForm = (props: AddEFTPaymentFormProps) => {
  const dispatch = useDispatch();
  const rdx_existingPaymentInfo = useSelector(rdx_getPaymentInfo);
  const { achPaymentEnabled } = props

  const [dupeConfirmOpen, setDupeConfirmOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SaveEFTInfoBody>({ mode: "all" });

  const accountTypes = ["Checking", "Savings", "Other"] as EFTAccountTypes[];

  const duplicateCheck = (accountNumber: string) => {
    return (
      rdx_existingPaymentInfo.find(
        (value) =>
          value.maskAccountNumber ===
          accountNumber.substring(accountNumber.length - 4) &&
          value.paymentType === "EFT"
      ) != null
    );
  };

  const onSubmit: SubmitHandler<SaveEFTInfoBody> = (data) => {
    if (duplicateCheck(data.accountNumber)) {
      setDupeConfirmOpen(true);
    } else {
      dispatch(rdx_saveEFTInfo(data));
    }
  };

  return (
    <>
      {achPaymentEnabled ?
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            select
            label="Account Type"
            className={"textbox-width"}
            defaultValue=""
            sx={{ textAlign: "left", mt: 2 }}
            inputProps={register("accountType", {
              required: "Account Type is required",
            })}
            error={!!errors.accountType}
            helperText={errors.accountType?.message}
          >
            {accountTypes.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Name on Account"
            className={"textbox-width"}
            autoComplete="off"
            sx={{ mt: 2 }}
            inputProps={{ maxLength: 75 }}
            error={!!errors.accountName}
            helperText={errors.accountName ? errors.accountName?.message : ""}
            {...register("accountName", {
              required: "Name on Account is required",
              maxLength: {
                value: 75,
                message: "Name on Account exceeds maximum length",
              },
              validate: (accountName) => nonAsciiCommasCheck(accountName),
            })}
          />
          <TextField
            label="Account Number"
            className={"textbox-width"}
            autoComplete="off"
            inputProps={{ maxLength: 17 }}
            sx={{ mt: 2 }}
            error={!!errors.accountNumber}
            helperText={errors.accountNumber ? errors.accountNumber?.message : ""}
            {...register("accountNumber", {
              required: "Account Number is required",
              pattern: {
                value: /^\d{6,17}$/,
                message: "Invalid Account Number",
              },
              maxLength: {
                value: 17,
                message: "Account Number exceeds maximum length",
              },
            })}
          />
          <TextField
            label="Routing Number"
            className={"textbox-width"}
            autoComplete="off"
            inputProps={{ maxLength: 9 }}
            sx={{ mt: 2 }}
            error={!!errors.routingNumber}
            helperText={errors.routingNumber ? errors.routingNumber?.message : ""}
            {...register("routingNumber", {
              required: "Routing number is required",
              pattern: {
                value: /^[0-9]{9}$/,
                message: "Invalid Routing Number",
              },
              maxLength: {
                value: 9,
                message: "Routing Number exceeds maximum length",
              },
              validate: (value) => validateEftRoutingNumber(value),
            })}
          />
          <TextField
            label="Email Confirmation"
            className={"textbox-width"}
            autoComplete="off"
            sx={{ mt: 2 }}
            inputProps={{ maxLength: 75 }}
            error={!!errors.emailConfirmation}
            helperText={
              errors.emailConfirmation ? errors.emailConfirmation?.message : ""
            }
            {...register("emailConfirmation", {
              required: "Confirmation Email is required",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,9}$/,
                message: "Invalid email address",
              },
              maxLength: {
                value: 75,
                message: "Email address exceeds maximum length",
              },
            })}
          />
          <Button
            type="submit"
            sx={{ mt: 3 }}
            variant="contained"
            color="secondary"
            size="large"
          >
            Submit
          </Button>

          <Dialog
            open={dupeConfirmOpen}
            onClose={() => setDupeConfirmOpen(false)}
          >
            <DialogTitle id="alert-dialog-title">
              {"Possible Duplicate"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                The provided card number may be a duplicate of an existing payment
                method. Do you still want to add it?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDupeConfirmOpen(false)}>Cancel</Button>
              <Button
                onClick={handleSubmit((obj) => {
                  setDupeConfirmOpen(false);
                  dispatch(rdx_saveEFTInfo(obj));
                })}
                autoFocus
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </form>
        :
        <p>
          Due to processing time for ACH payments, you may only add one bank account.
        </p>
      }
    </>
  )
};

export default AddEFTPaymentForm;
