import React from "react";
import { useSelector } from "react-redux";
import {
  AppBar,
  Box,
  Grid,
  Link,
  Toolbar,
  Typography,
  IconButton,
  ButtonGroup,
  SvgIcon,
  SvgIconProps,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { thin } from "@fortawesome/fontawesome-svg-core/import.macro";

import {
  rdx_getCustomerServicePhone,
  rdx_getCompanyName,
  rdx_getFacebookLink,
  rdx_getInstaLink,
  rdx_getLinkedinLink,
  rdx_getTwitterLink,
  rdx_getYoutubeLink,
  rdx_getEmailAddress,
} from "../../Store/Slices/tenantRegistationSlice";
import "./Footer.scss";

import _colors from "../../Content/_colors.scss";

function FacebookIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="5 -5 25 38">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2231 0.90918C7.36808 0.90918 0.189697 8.16456 0.189697 17.1145C0.189697 26.0645 7.36808 33.3199 16.2231 33.3199C25.078 33.3199 32.2564 26.0645 32.2564 17.1145C32.2564 8.16456 25.078 0.90918 16.2231 0.90918ZM17.8942 17.8264V26.6429H14.2851V17.8267H12.4819V14.7885H14.2851V12.9643C14.2851 10.4857 15.3032 9.01186 18.196 9.01186H20.6042V12.0504H19.0989C17.9728 12.0504 17.8983 12.475 17.8983 13.2675L17.8942 14.7881H20.6213L20.3022 17.8264H17.8942Z"
      />
    </SvgIcon>
  );
}

function InstagramIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="5 -5 25 38">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8053 0.90918C7.227 0.90918 0.272949 8.16456 0.272949 17.1145C0.272949 26.0645 7.227 33.3199 15.8053 33.3199C24.3835 33.3199 31.3376 26.0645 31.3376 17.1145C31.3376 8.16456 24.3835 0.90918 15.8053 0.90918ZM12.3903 8.52391C13.2739 8.48196 13.5562 8.47169 15.806 8.47169H15.8034C18.0539 8.47169 18.3352 8.48196 19.2189 8.52391C20.1008 8.56605 20.7031 8.71172 21.2312 8.92545C21.7765 9.14602 22.2373 9.44132 22.6981 9.92209C23.1589 10.4025 23.442 10.8847 23.6542 11.4531C23.8579 12.0027 23.9977 12.6308 24.0391 13.5509C24.0788 14.4728 24.0892 14.7674 24.0892 17.1146C24.0892 19.4619 24.0788 19.7558 24.0391 20.6777C23.9977 21.5974 23.8579 22.2257 23.6542 22.7754C23.442 23.3437 23.1589 23.8259 22.6981 24.3063C22.2379 24.7871 21.7764 25.0831 21.2317 25.3038C20.7046 25.5176 20.102 25.6632 19.2201 25.7054C18.3364 25.7473 18.055 25.7576 15.805 25.7576C13.5554 25.7576 13.2732 25.7473 12.3896 25.7054C11.5079 25.6632 10.9057 25.5176 10.3786 25.3038C9.83414 25.0831 9.37196 24.7871 8.91168 24.3063C8.45106 23.8259 8.16802 23.3437 7.95627 22.7752C7.75158 22.2257 7.61196 21.5976 7.57141 20.6775C7.53137 19.7556 7.52136 19.4619 7.52136 17.1146C7.52136 14.7674 7.53171 14.4726 7.57123 13.5507C7.61093 12.6309 7.75072 12.0027 7.95609 11.453C8.16837 10.8847 8.45141 10.4025 8.9122 9.92209C9.37265 9.4415 9.83483 9.1462 10.3797 8.92545C10.9064 8.71172 11.5084 8.56605 12.3903 8.52391Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0629 10.0291C15.2072 10.0289 15.3624 10.029 15.5299 10.0291L15.806 10.0291C18.0179 10.0291 18.28 10.0374 19.1535 10.0788C19.9611 10.1174 20.3995 10.2582 20.6915 10.3765C21.0781 10.5331 21.3537 10.7204 21.6435 11.0229C21.9334 11.3254 22.1129 11.6135 22.2634 12.0168C22.3768 12.3211 22.5119 12.7785 22.5487 13.6212C22.5884 14.5323 22.597 14.806 22.597 17.1126C22.597 19.4192 22.5884 19.6929 22.5487 20.604C22.5118 21.4466 22.3768 21.904 22.2634 22.2083C22.1133 22.6116 21.9334 22.8988 21.6435 23.2012C21.3535 23.5037 21.0783 23.6909 20.6915 23.8476C20.3999 23.9664 19.9611 24.1069 19.1535 24.1454C18.2802 24.1868 18.0179 24.1958 15.806 24.1958C13.594 24.1958 13.3319 24.1868 12.4586 24.1454C11.6509 24.1065 11.2126 23.9657 10.9204 23.8474C10.5338 23.6908 10.2577 23.5035 9.96772 23.201C9.67778 22.8985 9.4983 22.6111 9.34781 22.2076C9.23442 21.9033 9.09929 21.4459 9.06253 20.6032C9.02283 19.6921 9.01489 19.4184 9.01489 17.1104C9.01489 14.8024 9.02283 14.5301 9.06253 13.619C9.09946 12.7763 9.23442 12.319 9.34781 12.0143C9.49795 11.611 9.67778 11.3229 9.96772 11.0204C10.2577 10.7179 10.5338 10.5306 10.9204 10.3736C11.2124 10.2548 11.6509 10.1143 12.4586 10.0756C13.2228 10.0396 13.519 10.0288 15.0629 10.027V10.0291ZM20.228 11.4642C19.6791 11.4642 19.2339 11.9282 19.2339 12.501C19.2339 13.0736 19.6791 13.5382 20.228 13.5382C20.7768 13.5382 21.222 13.0736 21.222 12.501C21.222 11.9284 20.7768 11.4639 20.228 11.4639V11.4642ZM11.5519 17.1146C11.5519 14.6634 13.4566 12.6761 15.8059 12.676C18.1552 12.676 20.0595 14.6634 20.0595 17.1146C20.0595 19.5657 18.1554 21.5522 15.806 21.5522C13.4567 21.5522 11.5519 19.5657 11.5519 17.1146Z"
      />
      <path d="M15.806 14.2336C17.3309 14.2336 18.5673 15.5234 18.5673 17.1146C18.5673 18.7056 17.3309 19.9956 15.806 19.9956C14.2809 19.9956 13.0447 18.7056 13.0447 17.1146C13.0447 15.5234 14.2809 14.2336 15.806 14.2336Z" />
    </SvgIcon>
  );
}

function LinkedinIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="5 -5 25 38">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3876 0.90918C7.53262 0.90918 0.354248 8.16456 0.354248 17.1145C0.354248 26.0645 7.53262 33.3199 16.3876 33.3199C25.2426 33.3199 32.4209 26.0645 32.4209 17.1145C32.4209 8.16456 25.2426 0.90918 16.3876 0.90918ZM8.05134 14.3312H11.6848V25.3655H8.05134V14.3312ZM11.9242 10.9178C11.9006 9.8359 11.1351 9.01186 9.8921 9.01186C8.6491 9.01186 7.83647 9.8359 7.83647 10.9178C7.83647 11.9773 8.62508 12.8251 9.84494 12.8251H9.86816C11.1351 12.8251 11.9242 11.9773 11.9242 10.9178ZM20.6051 14.072C22.9962 14.072 24.7887 15.6495 24.7887 19.0387L24.7885 25.3655H21.1552V19.4621C21.1552 17.9793 20.6294 16.9675 19.314 16.9675C18.3102 16.9675 17.7123 17.6496 17.4497 18.3084C17.3536 18.5445 17.33 18.8735 17.33 19.2033V25.3657H13.6961C13.6961 25.3657 13.744 15.3668 13.6961 14.3314H17.33V15.8944C17.8123 15.143 18.676 14.072 20.6051 14.072Z"
      />
    </SvgIcon>
  );
}

function TwitterIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="5 -5 25 38">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4711 0.90918C7.61613 0.90918 0.437744 8.16456 0.437744 17.1145C0.437744 26.0645 7.61613 33.3199 16.4711 33.3199C25.3261 33.3199 32.5045 26.0645 32.5045 17.1145C32.5045 8.16456 25.3261 0.90918 16.4711 0.90918ZM16.0246 14.6418L15.9909 14.0811C15.89 12.6277 16.776 11.3003 18.1778 10.7853C18.6937 10.6022 19.5685 10.5793 20.1405 10.7395C20.3648 10.8082 20.7909 11.0371 21.0937 11.2431L21.6433 11.6207L22.2489 11.4262C22.5853 11.3232 23.0339 11.1515 23.2358 11.0371C23.4265 10.9341 23.5947 10.8769 23.5947 10.9112C23.5947 11.1057 23.1797 11.7695 22.8321 12.1357C22.361 12.6506 22.4956 12.6964 23.4489 12.3531C24.0209 12.1586 24.0321 12.1586 23.9199 12.376C23.8526 12.4904 23.505 12.8909 23.1349 13.2571C22.5068 13.8866 22.4732 13.9552 22.4732 14.4816C22.4732 15.2941 22.0919 16.9878 21.7106 17.9147C21.004 19.6542 19.49 21.4508 17.976 22.3549C15.8451 23.6251 13.0077 23.9456 10.6189 23.2017C9.82266 22.95 8.45443 22.3091 8.45443 22.1947C8.45443 22.1604 8.86938 22.1146 9.37406 22.1031C10.4283 22.0802 11.4825 21.7827 12.3797 21.2563L12.9853 20.8901L12.29 20.6498C11.303 20.3065 10.4171 19.5169 10.1928 18.773C10.1255 18.5327 10.1479 18.5213 10.7759 18.5213L11.4264 18.5098L10.8769 18.2466C10.2264 17.9147 9.632 17.354 9.34041 16.7818C9.12733 16.3698 8.85817 15.3285 8.93667 15.2484C8.9591 15.214 9.19462 15.2827 9.46378 15.3742C10.2376 15.6603 10.3385 15.5917 9.88995 15.111C9.04882 14.2413 8.79088 12.9482 9.19462 11.7237L9.38527 11.1744L10.1255 11.9182C11.6395 13.4174 13.4227 14.31 15.4638 14.5732L16.0246 14.6418Z"
      />
    </SvgIcon>
  );
}

function YoutubeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="5 -5 25 38">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0334 0.411865C7.17838 0.411865 0 7.66725 0 16.6172C0 25.5672 7.17838 32.8226 16.0334 32.8226C24.8884 32.8226 32.0667 25.5672 32.0667 16.6172C32.0667 7.66725 24.8884 0.411865 16.0334 0.411865ZM25.1572 10.6709C25.4876 11.0072 25.7248 11.4253 25.8452 11.8834C26.2903 13.5743 26.2903 17.1004 26.2903 17.1004C26.2903 17.1004 26.2903 20.6266 25.8452 22.3175C25.7248 22.7756 25.4876 23.1937 25.1572 23.5299C24.8269 23.8662 24.4151 24.1088 23.9629 24.2335C22.2984 24.6862 15.6452 24.6862 15.6452 24.6862C15.6452 24.6862 8.99193 24.6862 7.32742 24.2335C6.87526 24.1088 6.46341 23.8662 6.13308 23.5299C5.80275 23.1937 5.56553 22.7756 5.44516 22.3175C5 20.6266 5 17.1004 5 17.1004C5 17.1004 5 13.5743 5.44516 11.8834C5.56553 11.4253 5.80275 11.0072 6.13308 10.6709C6.46341 10.3347 6.87526 10.0921 7.32742 9.96741C8.99193 9.51471 15.6452 9.51471 15.6452 9.51471C15.6452 9.51471 22.2984 9.51471 23.9629 9.96741C24.4151 10.0921 24.8269 10.3347 25.1572 10.6709Z"
      />
      <path d="M13.4678 20.3028V13.8989L19.0323 17.1008L13.4678 20.3028Z" />
    </SvgIcon>
  );
}

export default function Footer() {
  const phoneNumber = useSelector(rdx_getCustomerServicePhone);
  const companyName = useSelector(rdx_getCompanyName);
  const facebooklink = useSelector(rdx_getFacebookLink);
  const instaLink = useSelector(rdx_getInstaLink);
  const linkedinLink = useSelector(rdx_getLinkedinLink);
  const twitterLink = useSelector(rdx_getTwitterLink);
  const youtubeLink = useSelector(rdx_getYoutubeLink);
  const email = useSelector(rdx_getEmailAddress);
  return (
    <Box className="footer" sx={{ flexGrow: 1 }}>
      <AppBar position="relative" color="primary" sx={{ bottom: 0 }}>
        <Toolbar>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontSize: ".75em", marginTop: "4px" }}
              >
                Contact Us
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="center-everything">
                <FontAwesomeIcon icon={thin("phone")} />
                <Link
                  sx={{ color: _colors.secondarywhite, fontSize: ".75em" }}
                  href={phoneNumber}
                >
                  &nbsp;&nbsp;Toll Free: {phoneNumber}
                </Link>
              </Typography>
              {(() => {
                if (email !== "") {
                  return (
                    <Typography className="center-everything">
                      <FontAwesomeIcon icon={thin("envelope")} />
                      <Link
                        sx={{
                          color: _colors.secondarywhite,
                          fontSize: ".75em",
                        }}
                        href={email}
                      >
                        &nbsp;&nbsp;{email}
                      </Link>
                    </Typography>
                  );
                }
                return null;
              })()}
            </Grid>
            <Grid item>
              <ButtonGroup
                variant="contained"
                orientation="horizontal"
                sx={{ paddingLeft: "10px" }}
              >
                <IconButton href={facebooklink}>
                  <FacebookIcon sx={{ color: _colors.secondarywhite }} />
                </IconButton>
                <IconButton href={instaLink}>
                  <InstagramIcon sx={{ color: _colors.secondarywhite }} />
                </IconButton>
                <IconButton href={linkedinLink}>
                  <LinkedinIcon sx={{ color: _colors.secondarywhite }} />
                </IconButton>
                <IconButton href={twitterLink}>
                  <TwitterIcon sx={{ color: _colors.secondarywhite }} />
                </IconButton>
                {(() => {
                  if (youtubeLink !== "") {
                    return (
                      <IconButton href={youtubeLink}>
                        <YoutubeIcon sx={{ color: _colors.secondarywhite }} />
                      </IconButton>
                    );
                  }
                  return null;
                })()}
              </ButtonGroup>
            </Grid>
          </Grid>
        </Toolbar>
        <Toolbar className="center-content" sx={{ backgroundColor: "#212121" }}>
          <Typography sx={{ fontSize: ".75em" }}>
            ©{new Date().getFullYear()}&nbsp;
          </Typography>
          <Typography sx={{ fontSize: ".75em" }}>
            <Link href="" color="secondary">
              {companyName}
            </Link>
          </Typography>
          <Typography sx={{ fontSize: ".75em" }}>
            &nbsp;All Rights Reserved
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
