import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { validateAddress } from "../../../Helpers/validationHelper";
import { setSnackbarSettings } from "../../../Store/Slices/snackbarSlice";
import {
  rdx_clearStepErrorMessage,
  rdx_getIsLoading,
  rdx_getStepValidationResult,
  rdx_loadCheckAddress,
} from "../../../Store/Slices/stepManagerSlice";
import AttemptsRemainingAlert from "../../HelperComponents/AttempsRemainingAlert/AttemptsRemainingAlert";
import Loading from "../../Loading/Loading";

const AddressCheck = () => {
  // From Redux
  const dispatch = useDispatch();
  const rdx_stepValidationResult = useSelector(
    rdx_getStepValidationResult("AddressCheck")
  );
  const rdx_IsLoading = useSelector(rdx_getIsLoading);
  // Local State
  const [address, setAddress] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (
      !rdx_stepValidationResult.success &&
      rdx_stepValidationResult.message !== ""
    ) {
      let [isValid, _] = validateAddress(address);

      if (isValid) {
        dispatch(
          setSnackbarSettings({
            open: true,
            severity: "error",
            message: "Failed Attempt",
          })
        );
        setTimeout(() => {
          dispatch(rdx_clearStepErrorMessage());
        }, 1500);
      }

      setError(true);
      setErrorMessage(rdx_stepValidationResult.message);
    }
  }, [rdx_stepValidationResult, address, dispatch]);

  const handleAddressValueChanged = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    let address = e.currentTarget.value;
    let [isValid, validationMessage] = validateAddress(address);

    setAddress(address);
    setError(!isValid);
    setErrorMessage(isValid ? "" : validationMessage);
  };

  const nextButtonClick = () => {
    let [isValid, _] = validateAddress(address);

    if (!error || isValid) {
      dispatch(rdx_loadCheckAddress(address));
    }
  };

  return (
    <>
      <CardContent>
        <Typography m={3} variant="h6" gutterBottom>
          Enter your address
        </Typography>
        {rdx_IsLoading && <Loading />}
        {!rdx_IsLoading && (
          <>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <TextField
                id="outlined-basic"
                label="Address"
                variant="outlined"
                autoComplete="off"
                placeholder="123 Example St"
                className="textbox-width"
                inputProps={{ maxLength: 100 }}
                onChange={handleAddressValueChanged}
                value={address}
                error={error}
                helperText={errorMessage}
              />
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              mt={3}
              mb={3}
            >
              <Button
                variant="contained"
                onClick={nextButtonClick}
                color="secondary"
                size="large"
              >
                Next
              </Button>
            </Grid>{" "}
          </>
        )}

        <AttemptsRemainingAlert />
      </CardContent>
    </>
  );
};

export default AddressCheck;
