import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isEmptyOrWhiteSpace } from "../../Helpers/stringHelper";
import { AppDispatch, AppThunk } from "../../index";
import ActionPayload from "../../Models/actionPayload";
import { unknownStep } from "../../Models/registrationStep";
import RegistrationType from "../../Models/registrationType";
import TokenBody from "../../Models/ServiceBody/tokenBody";
import { getServiceCallBeganAction } from "../../Models/serviceRequest";
import ServiceSuccessPayload from "../../Models/serviceSuccessPayload";
import RedirectUrlInfo from "../../Models/redirectUrlInfo";
import TenantRegistrationSliceState from "../../Models/SliceState/tenantRegistrationSliceState";
import TenantRegistration from "../../Models/tenantRegistration";
import { RootState } from "../reducer";
import redirectUrl from "../../Url/RedirectUrlFactory";
import getService from "../../Services/serviceFactory";

const defaultRegistrationType = {
  tenantRegistrationTypeID: 1,
  typeName: "Default",
  companyName: "Conservice",
  companyURL: "https://www.conservice.com",
  logoURL: "/Images/Logos/ConserviceLogoWhiteColor.png",
  loginSiteURL: process.env["REACT_APP_DEFAULT_UI_LOGIN_URL"],
  faviconURL: "/Images/Icons/favicon.ico",
  facebookURL: "https://www.facebook.com/Conservice",
  instagramURL: "https://instagram.com/conservice/",
  linkedInURL: "http://www.linkedin.com/company/conservice",
  twitterURL: "https://twitter.com/conservice?lang=en",
  youtubeURL: "https://www.youtube.com/channel/UCJbDEWQ0K5k2Pslqux-cA-A",
  customerServicePhone: "1-866-947-7379",
  customerServiceEmail: "",
  productName: "Conservice",
} as RegistrationType;

const wyseRegistrationType = {
  tenantRegistrationTypeID: 2,
  typeName: "Wyse",
  companyName: "Wyse Meter",
  companyURL: "https://www.wysemeter.com",
  logoURL: "/Images/Logos/WyseLogoWhiteSmall.png",
  loginSiteURL: process.env["REACT_APP_WYSE_UI_LOGIN_URL"],
  faviconURL: "/Images/Icons/wysefavicon.ico",
  facebookURL: "https://www.facebook.com/WyseMeter",
  instagramURL: "https://www.instagram.com/wysemeter/",
  linkedInURL: "https://www.linkedin.com/company/wyse-meter-solutions-inc-",
  twitterURL: "",
  youtubeURL: "",
  customerServicePhone: "1-844-411-0663",
  customerServiceEmail: "billing@wyseutilities.com",
  productName: "Wyse",
} as RegistrationType;

const redirectUrlInfo = {
  defaultUrl: process.env["REACT_APP_DEFAULT_URL"],
  wyseUrl: process.env["REACT_APP_WYSE_URL"],
} as RedirectUrlInfo;

const defaultMaintenanceResponse = {
  maintenanceBanner: false,
  maintenanceTakeDown: false,
  maintenanceMessage: "",
  startAt: "",
  endAt: "",
};

const slice = createSlice({
  name: "tenantRegistration",
  initialState: {
    isLoading: false,
    isInitialized: false,
    linkToken: "",
    newToken: "",
    registrationSteps: [
      {
        ...unknownStep,
      },
    ],
    lastRegistrationStep: { ...unknownStep },
    failedAttemptCount: 0,
    registrationComplete: false,
    username: "",
    registrationType: {
      ...defaultRegistrationType,
    },
    maintenanceResponse: {
      ...defaultMaintenanceResponse,
    },
    loadErrorMessage: "",
  } as TenantRegistrationSliceState,

  reducers: {
    getTenantRegistrationOnStart: (draft) => {
      draft.isLoading = true;
    },
    getTenantRegistrationOnSuccess: (
      draft,
      action: PayloadAction<
        ServiceSuccessPayload<TenantRegistration, TokenBody>
      >
    ) => {
      draft.isLoading = false;

      let item = action.payload.data;

      if (!isEmptyOrWhiteSpace(item.redirectUrl)) {
        window.location.href = item.redirectUrl;
        return;
      }

      if (
        isEmptyOrWhiteSpace(item.newToken) ||
        !item.authTokens ||
        item.authTokens.length === 0 ||
        item.authTokens.find((value) => !value.authToken)
      ) {
        draft.loadErrorMessage = "An error occurred while loading data.";
        return;
      }

      if (item.maintenanceResponse !== undefined) {
        draft.maintenanceResponse = item.maintenanceResponse;
      }

      draft.linkToken = action.payload.requestPayload.data.token;
      draft.newToken = item.newToken;
      draft.registrationSteps = item.registrationSteps;

      if (item.registrationSteps.length > 0) {
        draft.lastRegistrationStep = item.registrationSteps.find(
          (subItem) => subItem.isLastStep
        );
      }

      draft.failedAttemptCount = item.failedAttempts;
      draft.registrationComplete = item.registrationComplete;
      draft.username = item.username;

      if (item.registrationType.tenantRegistrationTypeID === 2) {
        draft.registrationType = {
          ...wyseRegistrationType,
        };
      } else {
        //
        // Copy the default type and then put in the action type ID and name
        draft.registrationType = {
          ...defaultRegistrationType,
        };

        draft.registrationType.tenantRegistrationTypeID =
          item.registrationType.tenantRegistrationTypeID;
        draft.registrationType.typeName = item.registrationType.typeName;
      }
      draft.registrationType.productName =
        item.registrationType.tenantRegistrationTypeID === 2
          ? "Wyse"
          : "Conservice";
      draft.registrationType.customerServicePhone = item.customerServicePhone;
      if (item.authTokens?.length > 0) {
        item.authTokens.forEach((value) => {
          let service = getService(value.serviceName);
          if (service) {
            service.authToken = value.authToken;
          }
        });
      }

      redirectUrl(
        redirectUrlInfo,
        item.registrationType.tenantRegistrationTypeID,
        window.location.href,
        window.location.origin
      );

      draft.isInitialized = true;
    },
    getTenantRegistrationOnError: (draft) => {
      draft.isLoading = false;
      draft.loadErrorMessage = "Error loading data";
    },

    setUsername: (draft, action: ActionPayload<string>) => {
      draft.username = action.payload;
    },
  },
});

// Selectors
export const rdx_getIsLoading = (state: RootState) =>
  state.entities.tenantRegistration.isLoading;

export const rdx_getIsInitialized = (state: RootState) =>
  state.entities.tenantRegistration.isInitialized;

export const rdx_getNewToken = (state: RootState) =>
  state.entities.tenantRegistration.newToken;

export const rdx_getLastRegistrationStepName = (state: RootState) =>
  state.entities.tenantRegistration.lastRegistrationStep.stepName;

export const rdx_getRegistrationComplete = (state: RootState) =>
  state.entities.tenantRegistration.registrationComplete;

export const rdx_getUsername = (state: RootState) =>
  state.entities.tenantRegistration.username;

export const rdx_getRegistrationTypeID = (state: RootState) =>
  state.entities.tenantRegistration.registrationType.tenantRegistrationTypeID;

export const rdx_getmaintenanceBanner = (state: RootState) =>
  state.entities.tenantRegistration.maintenanceResponse.maintenanceBanner;

export const rdx_getMaintenanceMessage = (state: RootState) =>
  state.entities.tenantRegistration.maintenanceResponse.maintenanceMessage;

export const rdx_getLogoURL = (state: RootState) =>
  state.entities.tenantRegistration.registrationType.logoURL;

export const rdx_getLoginSiteURL = (state: RootState) =>
  state.entities.tenantRegistration.registrationType.loginSiteURL;

export const rdx_getFaviconURL = (state: RootState) =>
  state.entities.tenantRegistration.registrationType.faviconURL;

export const rdx_getCustomerServicePhone = (state: RootState) =>
  state.entities.tenantRegistration.registrationType.customerServicePhone;

export const rdx_getEmailAddress = (state: RootState) =>
  state.entities.tenantRegistration.registrationType.customerServiceEmail;

export const rdx_getLoadErrorMessage = (state: RootState) =>
  state.entities.tenantRegistration.loadErrorMessage;

export const rdx_getCompanyName = (state: RootState) =>
  state.entities.tenantRegistration.registrationType.companyName;

export const rdx_getProductName = (state: RootState) =>
  state.entities.tenantRegistration.registrationType.productName;

export const rdx_getFacebookLink = (state: RootState) =>
  state.entities.tenantRegistration.registrationType.facebookURL;

export const rdx_getInstaLink = (state: RootState) =>
  state.entities.tenantRegistration.registrationType.instagramURL;

export const rdx_getLinkedinLink = (state: RootState) =>
  state.entities.tenantRegistration.registrationType.linkedInURL;

export const rdx_getTwitterLink = (state: RootState) =>
  state.entities.tenantRegistration.registrationType.twitterURL;

export const rdx_getYoutubeLink = (state: RootState) =>
  state.entities.tenantRegistration.registrationType.youtubeURL;
// Actions
const {
  getTenantRegistrationOnStart,
  getTenantRegistrationOnSuccess,
  getTenantRegistrationOnError,
  setUsername,
} = slice.actions;

// Action Creators
const getTenantRegistrationServiceCallBegan =
  getServiceCallBeganAction<TokenBody>();

export const rdx_loadTenantRegistration =
  (token: string): AppThunk =>
  (dispatch: AppDispatch) =>
    dispatch(
      getTenantRegistrationServiceCallBegan({
        serviceName: "tenantRegistrationApi",
        serviceActionPath: "/Registration/GetTenantRegistration",
        useAnonymousAuth: true,
        data: { token },
        headers: {
          "Content-Type": "application/json",
          AppName: "TenantRegistration",
        },
        method: "post",
        onStart: getTenantRegistrationOnStart.type,
        onSuccess: getTenantRegistrationOnSuccess.type,
        onError: getTenantRegistrationOnError.type,
      })
    );

export const rdx_setUsername =
  (username: string): AppThunk =>
  (dispatch: AppDispatch) =>
    dispatch(setUsername(username));

const tenantRegistrationReducer = slice.reducer;

export default tenantRegistrationReducer;
