import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Snackbar } from "@mui/material";

import {
  getSnackbarSettings,
  resetSnackBar,
} from "../../../Store/Slices/snackbarSlice";
export default function CustomSnackbar() {
  const dispatch = useDispatch();
  const snackbarSettings = useSelector(getSnackbarSettings);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(resetSnackBar());
  };

  return (
    <div>
      <Snackbar
        open={snackbarSettings.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackbarSettings.severity}
          sx={{ width: "100%" }}
        >
          {snackbarSettings.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
