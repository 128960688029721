import { createAction } from "@reduxjs/toolkit";
import { Method } from "axios";
import { ServicesAvailable } from "../Types/servicesAvailable";

export default interface ServiceRequest<TBody> {
  serviceName: ServicesAvailable;
  serviceActionPath: string;
  useAnonymousAuth?: boolean;
  method: Method;
  data?: TBody;
  headers?: {};
  onStart?: string;
  onSuccess?: string;
  onError?: string;
}

export const getServiceCallBeganAction = <T extends unknown>() =>
  createAction<ServiceRequest<T>>("service/callBegan");
