import React from "react";
import {
  Box,
  Button,
  Modal,
  Fade,
  Typography,
  Backdrop,
  Divider,
} from "@mui/material";
import TermsAndConditionsModalProps from "../../../Models/Props/termsAndConditionsModalProps";

const TermsAndConditionsModal = (props: TermsAndConditionsModalProps) => {
  const modalBoxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: 1,
    maxHeight: "70vh",
    overflowY: "auto",
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.modalIsOpen}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") props.handleModalClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      disableEscapeKeyDown
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.modalIsOpen}>
        <Box sx={modalBoxStyle}>
          <Typography id="transition-modal-title" variant="h6">
            Automatic Late Pay Service Terms and Conditions
          </Typography>
          <Typography id="transition-modal-description" paragraph>
            Please read the following terms and conditions (these “Terms and
            Conditions”) carefully. These Terms and Conditions are a legally
            binding agreement between you (collectively, “you,” or “your”), and
            Conservice, LLC (“we,” “our,” or “Conservice”). You may use our
            automatic late payment service (“Automatic Late Pay” ”) to have your
            past due invoices automatically charged to a payment method
            (credit/debit card or ACH/EFT) designated by you. If you use the
            Automatic Late Pay service, the following Terms and Conditions will
            apply to your use of the Automatic Late Pay service.
          </Typography>

          <Typography variant="h6">Introduction</Typography>
          <Typography paragraph>
            Automatic Late Pay is a service provided by Conservice by which you
            will have your past due invoices paid automatically. This payment is
            processed by Conservice and, as such, will appear on your credit
            card and/or bank statement as being paid to Conservice.
          </Typography>

          <Typography variant="h6">Automatic Late Pay Enrollment</Typography>
          <Typography paragraph>
            By enrolling in Automatic Late Pay services, you accept and agree to
            be bound by these Terms and Conditions and authorize Conservice to
            charge your specified payment method. By enrolling and inputting
            your payment method information, you represent and warrant that you
            are the person whose name appears on payment method account, that
            you are authorized to use the payment method, that you have a
            sufficient funds or credit limit to cover any charges that may be
            made using Automatic Late Pay, that the information you provide is
            correct, and that you will notify us of any change in the above, and
            you further make each of these representations and warranties each
            time you have a transaction that will be paid through Automatic Late
            Pay. You agree to be bound by any rules your financial institution
            requires or debit card or credit card issuer requires for you
            payment method transactions. You are required to have at least one
            payment method on file for Automatic Late Pay at all times. If you
            provide more than one payment method you will be prompted to rank
            you payment methods according to the order in which you would like
            Conservice to attempt to process a payment. Conservice will attempt
            to process Automatic Late Pay payments using the highest ranked
            method first, and continuing through all available methods until
            payment is complete.
          </Typography>

          <Typography variant="h6">
            Invoices/Statements/Notifications and Payment Authorization
          </Typography>
          <Typography paragraph>
            Conservice will send your invoices according to your selected
            account preference. You agree that Conservice may send notifications
            to the email address and/or by text (SMS) to the phone number you
            provide. Past due balances will be processed the day after the due
            date, or shortly thereafter. You authorize Conservice to charge any
            payment method you provide (plus a $3.00 convenience fee for
            credit/debit card transactions, and $0.50 for ACH/EFT transactions)
            and you authorize your financial institution to deduct any
            checking/savings payment from your checking/savings account. You
            also authorize Conservice to credit your specified payment method in
            the appropriate amount for any refunds or other billing adjustments.
          </Typography>

          <Typography variant="h6">Returned Payments</Typography>
          <Typography paragraph>
            You understand that if your payment is rejected, refused, returned,
            disputed, or reversed by your financial institution or card issuer
            for any reason, then Conservice holds the right to charge you a
            rejected transaction fee or any other available penalties upon the
            card dispute, including any and all applicable late fees. You
            understand that Conservice may incur overdraft fees from your
            financial institution, if applicable.
          </Typography>

          <Typography variant="h6">
            Changes to Bank or Card Information
          </Typography>
          <Typography paragraph>
            You must notify Conservice of any change in your payment method(s)
            (including expiration date or other information required to process
            payments) to have your bill paid using your payment method(s). You
            may choose another payment method or update your current payment
            information by notifying Conservice at utilitiesinfo.conservice.com
            and providing the new payment method information at least three (3)
            business days prior to the next scheduled payment date.
          </Typography>

          <Typography variant="h6">Revocation</Typography>
          <Typography paragraph>
            Your authorization and the automatic payment service will remain in
            full force and effect until revoked by you, your financial
            institution, or Conservice. Enrollment will automatically be
            terminated in the event that you move out of your current apartment
            and your community notifies Conservice that you are no longer
            eligible for enrollment.
          </Typography>

          <Typography variant="h6">Disputes/Errors</Typography>
          <Typography paragraph>
            Conservice is not liable for erroneous bill statements or incorrect
            debits/charges. If a billing error occurs, you must notify
            Conservice of the error. In the event of a dispute regarding
            Automatic Late Pay, you and Conservice agree to resolve the dispute
            by looking to these Terms and Conditions. Most customer concerns can
            be resolved quickly by calling our Customer Service Department at
            1-866-947-7379. You agree that these Terms and Conditions are a
            complete and exclusive statement of the agreement between you and
            Conservice which supersedes any proposal or prior agreement, oral or
            written, and any other communications between you and Conservice
            relating to the Automatic Late Pay program. If there is a conflict
            between what an employee of Conservice or the Customer Service
            Department says and these Terms and Conditions, the Terms and
            Conditions will prevail.
          </Typography>
          <Typography paragraph>
            Conservice shall bear no liability or responsibility for any losses
            of any kind that you may incur as a result of a payment made on
            items incorrectly billed or for any delay in the actual date on
            which your account is debited or your credit or debit card is
            charged.
          </Typography>

          <Typography variant="h6">Changes and Updates</Typography>
          <Typography paragraph>
            Conservice reserves the right to change these terms and conditions
            at any time. Notice of any such change may be given on or with your
            bill or by other methods.
          </Typography>

          <Typography variant="h6">Governing Law</Typography>
          <Typography paragraph>
            These Terms and Conditions shall be governed and construed in
            accordance with the laws of the State of Utah, without regard to its
            conflicts of laws provisions. Except as otherwise provided herein,
            if any provision of this Agreement is found invalid or
            unenforceable, that provision shall be enforced to the maximum
            extent possible, and the other provisions contained herein will
            remain in full force and effect. The foregoing shall constitute
            Conservice’s entire liability and your exclusive remedy with respect
            to Automatic Late Pay.
          </Typography>
          <Typography paragraph>
            These Terms and Conditions represent the entire Automatic Late Pay
            agreement and understanding between the parties. This Agreement is
            binding upon the parties and their successors. You may not alter,
            modify or amend Agreement. These terms do not in any way terminate,
            amend, or modify other terms, agreements or policies that apply to
            your Conservice account or any Conservice services you receive or
            other agreements you may have with Conservice.
          </Typography>
          <Divider sx={{ my: 3, background: "black" }} />
          <Typography sx={{ mt: 3 }} variant="h6">
            Auto Pay Service Terms and Conditions
          </Typography>
          <Typography paragraph>
            I hereby authorize Conservice to set up automatic monthly payments 
            (credit/debit card or ACH/EFT), in which they will initiate a charge 
            to my designated payment method each month for the amount due on my 
            Conservice account. A $3.00 convenience fee will be assessed for 
            credit/debit card transactions. No convenience fee will be charged 
            for ACH/EFT transactions. If you provide more than one payment method 
            you will be prompted to rank your payment methods according to the 
            order in which you would like Conservice to attempt to process a 
            payment. Conservice will attempt to process AutoPay payments using 
            the highest ranked method first, and continuing through all available 
            methods until payment is complete. I acknowledge that a fee may be
            charged by Conservice in the event that Conservice is unable to secure 
            funds from my designated payment method for any reason; including, but 
            not limited to, insufficient funds or inaccurate information provided at
            the time the payment is submitted. The amount of the Returned Payment 
            fee is administered in accordance with applicable state and federal law. 
            I acknowledge that the origination of my designated payment method must
            comply with the provisions of U.S. law. Outstanding balances will 
            automatically be processed for payment at the time charges are posted or 
            soon thereafter (on a monthly basis or other frequency as outlined in the 
            rental agreement documents). In order to have my payment deducted I must 
            sign up prior to 8am MST on the date charges are posted. This authorization 
            will remain in full force and effect until Conservice receives notification 
            from me of its termination in such time and in such manner to afford Conservice 
            and my financial institution a reasonable opportunity to act on it. I understand 
            that in order to have my automatic payments cancelled before my next payment, 
            notification must be given to Conservice no later than 3 business days before 
            my next charges are posted. If I am enrolled for automatic payments and would 
            not like a payment to be withdrawn from my bank account on my payment, I must 
            cancel my enrollment 3 business days prior to my next charges being posted.
            If my bank account information needs updating, this also must be done 3 business
            days before my next charges are posted. In the event that I move out of my 
            current unit, this service will automatically be terminated.
          </Typography>
          <Box textAlign="center">
            <Button
              variant="contained"
              onClick={props.handleAcceptTerms}
              color="secondary"
              size="large"
              sx={{ mt: 3, mb: 3 }}
            >
              Accept Terms & Conditions
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default TermsAndConditionsModal;
