import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk, GetStateType } from "../../index";
import SendSmsVerificationCodeBody from "../../Models/ServiceBody/sendSmsVerificationCodeBody";
import VerifySmsCodeBody from "../../Models/ServiceBody/verifySmsCodeBody";
import ServiceValidationResult from "../../Models/serviceValidationResult";
import { getServiceCallBeganAction } from "../../Models/serviceRequest";
import SmsVerificationSliceState from "../../Models/SliceState/smsVerificationSliceState";
import ValidationResult from "../../Models/validationResult";
import { RootState } from "../reducer";
import SendSmsResult from "../../Models/sendSmsResult";
import ServiceSuccessPayload from "../../Models/serviceSuccessPayload";

const slice = createSlice({
  name: "smsValidation",
  initialState: {
    isLoading: false,
    isVerificationMessageSent: null as SendSmsResult,
    isCodeVerified: null as ValidationResult,
  } as SmsVerificationSliceState,
  reducers: {
    smsValidationCallOnStart: (draft) => {
      draft.isLoading = true;
    },
    sendSmsValidationCodeOnSucess: (
      draft,
      action: PayloadAction<
        ServiceSuccessPayload<SendSmsResult, SendSmsVerificationCodeBody>
      >
    ) => {
      draft.isLoading = false;
      draft.isVerificationMessageSent = action.payload.data;
    },
    verifySmsCodeOnSucess: (
      draft,
      action: PayloadAction<
        ServiceSuccessPayload<ServiceValidationResult, VerifySmsCodeBody>
      >
    ) => {
      draft.isLoading = false;
      draft.isCodeVerified = action.payload.data;
    },
    sendSmsValidationCodeOnError: (
      draft,
      action: PayloadAction<{ message: string }>
    ) => {
      draft.isVerificationMessageSent = {
        success: false,
        message: action.payload.message,
        sid: "",
      };
    },
    verifySmsCodeOnError: (
      draft,
      action: PayloadAction<{ message: string }>
    ) => {
      draft.isCodeVerified = {
        success: false,
        message: action.payload.message,
        failedAttemptCount: 0,
      };
    },
  },
});
// selectors
export const rdx_getIsLoading = (state: RootState) =>
  state.entities.smsVerification.isLoading;

export const rdx_getIsVerificationMessageSent = (state: RootState) =>
  state.entities.smsVerification.isVerificationMessageSent;

export const rdx_getIsCodeVerified = (state: RootState) =>
  state.entities.smsVerification.isCodeVerified;

const {
  smsValidationCallOnStart,
  sendSmsValidationCodeOnSucess: sendSmsValidationCodeOnCallSucess,
  sendSmsValidationCodeOnError: sendSmsValidationCodeOnCallError,
  verifySmsCodeOnSucess: verifySmsCodeOnCallSucess,
  verifySmsCodeOnError: verifySmsCodeOnCallError,
} = slice.actions;

const sendSmsVerificationCodeServiceCallBegan =
  getServiceCallBeganAction<SendSmsVerificationCodeBody>();

export const rdx_sendSmsVerificationCode =
  (verifyPhoneNumberInfo: SendSmsVerificationCodeBody): AppThunk =>
  (dispatch: AppDispatch, getState: GetStateType) => {
    let tenantRegistrationState = getState().entities.tenantRegistration;
    verifyPhoneNumberInfo.token = tenantRegistrationState.linkToken;

    dispatch(
      sendSmsVerificationCodeServiceCallBegan({
        serviceName: "tenantCommunicationService",
        serviceActionPath: "/Phone/SendVerificationCode",
        data: {
          ...verifyPhoneNumberInfo,
        },
        headers: {
          "Content-Type": "application/json",
        },
        method: "post",
        onStart: smsValidationCallOnStart.type,
        onSuccess: sendSmsValidationCodeOnCallSucess.type,
        onError: sendSmsValidationCodeOnCallError.type,
      })
    );
  };

const verifySmsCodeServiceCallBegan =
  getServiceCallBeganAction<VerifySmsCodeBody>();

export const rdx_verifySmsCode =
  (verifyCodeInfo: VerifySmsCodeBody): AppThunk =>
  (dispatch: AppDispatch, getState: GetStateType) => {
    let tenantRegistrationState = getState().entities.tenantRegistration;
    verifyCodeInfo.token = tenantRegistrationState.linkToken;

    dispatch(
      verifySmsCodeServiceCallBegan({
        serviceName: "tenantCommunicationService",
        serviceActionPath: "/Phone/VerifySMSCode",
        data: {
          ...verifyCodeInfo,
        },
        headers: {
          "Content-Type": "application/json",
        },
        method: "post",
        onStart: smsValidationCallOnStart.type,
        onSuccess: verifySmsCodeOnCallSucess.type,
        onError: verifySmsCodeOnCallError.type,
      })
    );
  };
export default slice.reducer;
