import { createTheme } from "@mui/material/styles";
import _colors from "../Content/_colors.scss";

const wyseTheme = createTheme({
  palette: {
    primary: {
      main: _colors.wysedarkblue,
      contrastText: _colors.secondarywhite,
    },
    secondary: {
      main: _colors.wysegreen,
      contrastText: _colors.wyseblack,
    },
    info: {
      main: _colors.wysedarkblue,
    },
  },
  typography: {
    fontFamily: "Monument Grotesk, Monument Grotesk Medium",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        root {
          font-family: "Monument Grotesk, Monument Grotesk Medium",
        }`,
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: _colors.secondarylightgray,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: _colors.wyselightblue,
          },
          "&.Mui-selected": {
            backgroundColor: _colors.wyseblue,
            "&:hover": {
              backgroundColor: _colors.wysetblue,
            },
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: _colors.wyselightblue,
          width: "100%",
          height: "0.9rem",
        },
        colorPrimary: {
          backgroundColor: _colors.secondarylightgray,
        },
        barColorPrimary: {
          backgroundColor: _colors.wysedarkblue,
        },
      },
    },
  },
});

export default wyseTheme;
