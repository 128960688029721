import React from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";

import {
  rdx_getCurrentRegistrationStepName,
  rdx_getProgressPercentage,
} from "../../Store/Slices/stepManagerSlice";
import DynamicComponent from "../HelperComponents/DynamicComponent/DynamicComponent";
import LinearProgressWithLabel from "../LinearProgressWithLabel/LinearProgressWithLabel";

const Stepper = () => {
  // From Redux
  const rdx_currentRegistrationStepName = useSelector(
    rdx_getCurrentRegistrationStepName
  );
  const rdx_progressPercentage = useSelector(rdx_getProgressPercentage);

  return (
    <>
      <Typography variant="h4" mb={3}>
        Account Registration
      </Typography>
      <Box sx={{ mx: 2 }}>
        <LinearProgressWithLabel value={rdx_progressPercentage} />
      </Box>
      <Box sx={{ margin: "auto" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        ></Grid>
        <DynamicComponent componentName={rdx_currentRegistrationStepName} />
      </Box>
    </>
  );
};

export default Stepper;
