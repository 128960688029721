import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StepsAvailable } from "../../Types/availableSteps";
import { AppDispatch, AppThunk, GetStateType } from "../../index";
import ActionPayload from "../../Models/actionPayload";
import AutoPayBody from "../../Models/ServiceBody/autoPayBody";
import CheckAddressBody from "../../Models/ServiceBody/checkAddressBody";
import CheckCommunityBody from "../../Models/ServiceBody/checkCommunityBody";
import CheckPinBody from "../../Models/ServiceBody/checkPinBody";
import CheckPostalCodeBody from "../../Models/ServiceBody/checkPostalCodeBody";
import SetUpAccountAccessBody from "../../Models/ServiceBody/setUpAccountAccessBody";
import { getServiceCallBeganAction } from "../../Models/serviceRequest";
import ServiceValidationResult from "../../Models/serviceValidationResult";
import RegistrationStep, { unknownStep } from "../../Models/registrationStep";
import StepManagerSliceState from "../../Models/SliceState/stepManagerSliceState";
import StepManagerSliceInitializeParams from "../../Models/stepManagerSliceInitializeParams";
import ValidationResult from "../../Models/validationResult";
import { RootState } from "../reducer";
import TokenBody from "../../Models/ServiceBody/tokenBody";
import ServiceSuccessPayload from "../../Models/serviceSuccessPayload";

const slice = createSlice({
  name: "stepManager",
  initialState: {
    isLoading: false,
    isInitialized: false,
    registrationSteps: [
      {
        ...unknownStep,
      },
    ],
    registrationStepQueue: [],
    currentStep: { ...unknownStep },
    failedAttemptCount: 0,
  } as StepManagerSliceState,

  reducers: {
    stepActionOnStart: (draft) => {
      draft.isLoading = true;
    },
    stepActionOnSuccess: (
      draft,
      action: PayloadAction<ServiceSuccessPayload<ServiceValidationResult, any>>
    ) => {
      draft.isLoading = false;

      const item = action.payload.data;

      if (item.success) {
        draft.currentStep = draft.registrationStepQueue.shift();
      } else {
        draft.failedAttemptCount = item.failedAttemptCount;

        let registrationStep = draft.registrationSteps.find(
          (subItem) => subItem.stepName === draft.currentStep.stepName
        );

        if (registrationStep !== undefined && registrationStep !== null) {
          registrationStep.lastValidationResult = { ...item };
        }
      }
    },
    stepActionClearErrorMessageSucess: (draft) => {
      let registrationStep = draft.registrationSteps.find(
        (subItem) => subItem.stepName === draft.currentStep.stepName
      );
      if (registrationStep !== undefined && registrationStep !== null) {
        registrationStep.lastValidationResult.message = "";
      }
    },
    stepActionOnError: (draft) => {
      draft.isLoading = false;
    },

    initializeSlice: (
      draft,
      action: ActionPayload<StepManagerSliceInitializeParams>
    ) => {
      let params = action.payload;

      draft.failedAttemptCount = params.failedAttemptCount;

      let registrationSteps = params.registrationSteps;
      let registrationStepsQueue = registrationSteps.map((val) => {
        return { ...val };
      });

      let currentStep = registrationStepsQueue.shift();

      draft.registrationSteps = registrationSteps;
      draft.registrationStepQueue = registrationStepsQueue;
      draft.currentStep = currentStep;

      draft.isInitialized = true;
      draft.isLoading = false;
    },
  },
});

// Selectors
export const rdx_getCurrentRegistrationStepName = (state: RootState) =>
  state.entities.stepManager.currentStep.stepName;

export const rdx_getProgressPercentage = (state: RootState) => {
  let stepManager = state.entities.stepManager;
  let registrationSteps = stepManager.registrationSteps;
  let registrationStepQueue = stepManager.registrationStepQueue;
  let arrayLength = registrationSteps.length;
  let queueLength = registrationStepQueue.length;

  return registrationSteps.length === 1
    ? 100
    : 100 * (1 - queueLength / (arrayLength - 1));
};

export const rdx_getFailedAttemptCount = (state: RootState) =>
  state.entities.stepManager.failedAttemptCount;

export const rdx_getAttemptsRemaining = (state: RootState) => {
  let attempts = state.entities.stepManager.failedAttemptCount;
  return attempts > 2 ? 0 : 3 - attempts;
};

export const rdx_getStepValidationResult =
  (stepName: StepsAvailable) => (state: RootState) => {
    let stepManager = state.entities.stepManager;
    let registrationStep: RegistrationStep;

    if (stepManager.registrationSteps.length > 0) {
      registrationStep = stepManager.registrationSteps.find(
        (subItem) => subItem.stepName === stepName
      );
    }

    return registrationStep !== undefined &&
      registrationStep !== null &&
      registrationStep.lastValidationResult !== undefined &&
      registrationStep.lastValidationResult !== null
      ? registrationStep.lastValidationResult
      : ({ success: false, message: "" } as ValidationResult);
  };

export const rdx_getIsInitialized = (state: RootState) =>
  state.entities.stepManager.isInitialized;

export const rdx_getIsLoading = (state: RootState) =>
  state.entities.stepManager.isLoading;

// Actions
const {
  stepActionOnStart,
  stepActionOnSuccess,
  stepActionOnError,
  stepActionClearErrorMessageSucess,
  initializeSlice,
} = slice.actions;

// Action Creators
const checkAddressServiceCallBegan =
  getServiceCallBeganAction<CheckAddressBody>();

export const rdx_loadCheckAddress =
  (address: string): AppThunk =>
  (dispatch: AppDispatch, getState: GetStateType) => {
    let tenantRegistrationState = getState().entities.tenantRegistration;
    let token = tenantRegistrationState.newToken;

    dispatch(
      checkAddressServiceCallBegan({
        serviceName: "tenantRegistrationApi",
        serviceActionPath: "/Registration/CheckAddress",
        data: {
          token,
          address,
        },
        headers: {
          "Content-Type": "application/json",
          AppName: "TenantRegistration",
        },
        method: "post",
        onStart: stepActionOnStart.type,
        onSuccess: stepActionOnSuccess.type,
        onError: stepActionOnError.type,
      })
    );
  };

const checkCommunityServiceCallBegan =
  getServiceCallBeganAction<CheckCommunityBody>();

export const rdx_loadCheckCommunity =
  (communityName: string): AppThunk =>
  (dispatch: AppDispatch, getState: GetStateType) => {
    let tenantRegistrationState = getState().entities.tenantRegistration;
    let token = tenantRegistrationState.newToken;

    dispatch(
      checkCommunityServiceCallBegan({
        serviceName: "tenantRegistrationApi",
        serviceActionPath: "/Registration/CheckCommunity",
        data: {
          token,
          community: communityName,
        },
        headers: {
          "Content-Type": "application/json",
          AppName: "TenantRegistration",
        },
        method: "post",
        onStart: stepActionOnStart.type,
        onSuccess: stepActionOnSuccess.type,
        onError: stepActionOnError.type,
      })
    );
  };

const checkPinServiceCallBegan = getServiceCallBeganAction<CheckPinBody>();

export const rdx_loadCheckPin =
  (pin: string): AppThunk =>
  (dispatch: AppDispatch, getState: GetStateType) => {
    let tenantRegistrationState = getState().entities.tenantRegistration;
    let token = tenantRegistrationState.newToken;

    dispatch(
      checkPinServiceCallBegan({
        serviceName: "tenantRegistrationApi",
        serviceActionPath: "/Registration/CheckPin",
        data: {
          token,
          pin,
        },
        headers: {
          "Content-Type": "application/json",
          AppName: "TenantRegistration",
        },
        method: "post",
        onStart: stepActionOnStart.type,
        onSuccess: stepActionOnSuccess.type,
        onError: stepActionOnError.type,
      })
    );
  };

const checkPostalCodeServiceCallBegan =
  getServiceCallBeganAction<CheckPostalCodeBody>();

export const rdx_loadCheckPostalCode =
  (postalCode: string): AppThunk =>
  (dispatch: AppDispatch, getState: GetStateType) => {
    let tenantRegistrationState = getState().entities.tenantRegistration;
    let token = tenantRegistrationState.newToken;

    dispatch(
      checkPostalCodeServiceCallBegan({
        serviceName: "tenantRegistrationApi",
        serviceActionPath: "/Registration/CheckPostalCode",
        data: {
          token,
          postalCode,
        },
        headers: {
          "Content-Type": "application/json",
          AppName: "TenantRegistration",
        },
        method: "post",
        onStart: stepActionOnStart.type,
        onSuccess: stepActionOnSuccess.type,
        onError: stepActionOnError.type,
      })
    );
  };

const setUpAccountAccessServiceCallBegan =
  getServiceCallBeganAction<SetUpAccountAccessBody>();

export const rdx_loadSetUpAccountAccess =
  (username: string, password: string): AppThunk =>
  (dispatch: AppDispatch, getState: GetStateType) => {
    let tenantRegistrationState = getState().entities.tenantRegistration;
    let token = tenantRegistrationState.newToken;

    dispatch(
      setUpAccountAccessServiceCallBegan({
        serviceName: "tenantRegistrationApi",
        serviceActionPath: "/Registration/SetUpAccountAccess",
        data: {
          token,
          username,
          password,
        },
        headers: {
          "Content-Type": "application/json",
          AppName: "TenantRegistration",
        },
        method: "post",
        onStart: stepActionOnStart.type,
        onSuccess: stepActionOnSuccess.type,
        onError: stepActionOnError.type,
      })
    );
  };

const completeAuthorizeSmsStepServiceCallBegan =
  getServiceCallBeganAction<TokenBody>();

export const rdx_completeAuthorizeSmsStep =
  (): AppThunk => (dispatch: AppDispatch, getState: GetStateType) => {
    let tenantRegistrationState = getState().entities.tenantRegistration;
    let token = tenantRegistrationState.linkToken;

    dispatch(
      completeAuthorizeSmsStepServiceCallBegan({
        serviceName: "tenantRegistrationApi",
        serviceActionPath: "/Registration/CompleteAuthorizeSmsStep",
        data: { token },
        headers: {
          "Content-Type": "application/json",
          AppName: "TenantRegistration",
        },
        method: "post",
        onStart: stepActionOnStart.type,
        onSuccess: stepActionOnSuccess.type,
        onError: stepActionOnError.type,
      })
    );
  };

const completeReviewTermsStepServiceCallBegan =
  getServiceCallBeganAction<AutoPayBody>();

export const rdx_completeReviewTermsStep =
  (isAutoPay: boolean): AppThunk =>
  (dispatch: AppDispatch, getState: GetStateType) => {
    let tenantRegistrationState = getState().entities.tenantRegistration;
    let token = tenantRegistrationState.linkToken;

    dispatch(
      completeReviewTermsStepServiceCallBegan({
        serviceName: "tenantRegistrationApi",
        serviceActionPath: "/Registration/CompleteReviewTermsStep",
        data: { token, isAutoPay },
        headers: {
          "Content-Type": "application/json",
          AppName: "TenantRegistration",
        },
        method: "post",
        onStart: stepActionOnStart.type,
        onSuccess: stepActionOnSuccess.type,
        onError: stepActionOnError.type,
      })
    );
  };

const completePaymentMethodsStepServiceCallBegan =
  getServiceCallBeganAction<TokenBody>();

export const rdx_completePaymentMethodsStep =
  (): AppThunk => (dispatch: AppDispatch, getState: GetStateType) => {
    let tenantRegistrationState = getState().entities.tenantRegistration;
    let token = tenantRegistrationState.linkToken;

    dispatch(
      completePaymentMethodsStepServiceCallBegan({
        serviceName: "tenantRegistrationApi",
        serviceActionPath: "/Registration/CompletePaymentMethodsStep",
        data: { token },
        headers: {
          "Content-Type": "application/json",
          AppName: "TenantRegistration",
        },
        method: "post",
        onStart: stepActionOnStart.type,
        onSuccess: stepActionOnSuccess.type,
        onError: stepActionOnError.type,
      })
    );
  };
export const rdx_clearStepErrorMessage =
  (): AppThunk => (dispatch: AppDispatch) => {
    dispatch(stepActionClearErrorMessageSucess());
  };
export const rdx_initializeStepManager =
  (): AppThunk => (dispatch: AppDispatch, getState: GetStateType) => {
    let tenantRegistrationState = getState().entities.tenantRegistration;
    let failedAttemptCount = tenantRegistrationState.failedAttemptCount;
    let registrationSteps = tenantRegistrationState.registrationSteps;

    dispatch(initializeSlice({ failedAttemptCount, registrationSteps }));
  };

const stepManagerReducer = slice.reducer;

export default stepManagerReducer;
