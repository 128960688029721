import React from "react";
import { useSelector } from "react-redux";
import { Card, Grid } from "@mui/material";

import DynamicComponent from "../HelperComponents/DynamicComponent/DynamicComponent";
import Footer from "../Footer/Footer";
import MainProps from "../../Models/Props/mainProps";
import Navbar from "../Navbar/Navbar";
import {
  rdx_getIsInitialized as rdx_getIsTenantRegistrationSliceInitialized,
  rdx_getLoadErrorMessage,
} from "../../Store/Slices/tenantRegistationSlice";
import { rdx_getIsInitialized as rdx_getIsStepManagerSliceInitialized } from "../../Store/Slices/stepManagerSlice";

import "./Main.scss";
import Loading from "../Loading/Loading";
import { isEmptyOrWhiteSpace } from "../../Helpers/stringHelper";

const Main = (props: MainProps) => {
  // From Redux
  const rdx_isTenantRegistrationSliceInitialized = useSelector(
    rdx_getIsTenantRegistrationSliceInitialized
  );

  const rdx_isStepManagerSliceInitialized = useSelector(
    rdx_getIsStepManagerSliceInitialized
  );

  const rdx_loadErrorMessage = useSelector(rdx_getLoadErrorMessage);

  if (
    (rdx_isTenantRegistrationSliceInitialized &&
      rdx_isStepManagerSliceInitialized) ||
    !isEmptyOrWhiteSpace(rdx_loadErrorMessage) ||
    props.componentName === "ContactCustomerService"
  ) {
    return (
      <>
        <div id="content-wrapper">
          <Navbar />
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            mt={5}
          >
            <Card className={"card-width"}>
              <DynamicComponent componentName={props.componentName} />
            </Card>
          </Grid>
        </div>
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Loading />
      </>
    );
  }
};

export default Main;
