import RedirectUrlInfo from "../Models/redirectUrlInfo";

const redirectUrl = (
  url: RedirectUrlInfo,
  registrationTypeID: number,
  pageURL: string,
  orginURL: string
) => {
  let lowerPageURL = pageURL.toLowerCase();
  if (lowerPageURL.includes("localhost") || lowerPageURL.includes("webdev")) {
    return;
  }

  let isWyseUrl = lowerPageURL.includes("wyse");
  let isWyseType = registrationTypeID === 2;

  if (isWyseUrl && !isWyseType) {
    window.location.href = pageURL.replace(orginURL, url.defaultUrl);
  }

  if (!isWyseUrl && isWyseType) {
    window.location.href = pageURL.replace(orginURL, url.wyseUrl);
  }
};

export default redirectUrl;
