import { combineReducers } from "@reduxjs/toolkit";
import maintenanceReducer from "./Slices/maintenanceSlice";
import paymentReducer from "./Slices/paymentSlice";
import smsVerificationReducer from "./Slices/smsVerificationSlice";
import snackbarReducer from "./Slices/snackbarSlice";
import stepManagerReducer from "./Slices/stepManagerSlice";
import tenantRegistrationReducer from "./Slices/tenantRegistationSlice";

export default combineReducers({
  payment: paymentReducer,
  smsVerification: smsVerificationReducer,
  snackbar: snackbarReducer,
  stepManager: stepManagerReducer,
  tenantRegistration: tenantRegistrationReducer,
  maintenance: maintenanceReducer,
});
