import React from "react";
import { CircularProgress } from "@mui/material";
function Loading() {
  return (
    <>
      <CircularProgress />
    </>
  );
}

export default Loading;
