import { createTheme } from "@mui/material/styles";
import _colors from "../Content/_colors.scss";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: _colors.primarynavy,
      contrastText: _colors.secondarywhite,
    },
    secondary: {
      main: _colors.primarygreen,
      contrastText: _colors.primarynavy,
    },
    info: {
      main: _colors.primaryblue,
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        font-family: "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,Ubuntu, Cantarell,Fira Sans, Droid Sans,Helvetica Neue,sans-serif"`,
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: _colors.lightestgray,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: _colors.lightestblue,
          },
          "&.Mui-selected": {
            backgroundColor: _colors.lightblue,
            "&:hover": {
              backgroundColor: _colors.lightblue,
            },
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: _colors.lightestblue,
          width: "100%",
          height: "0.9rem",
        },
        colorPrimary: {
          backgroundColor: _colors.lightestgray,
        },
        barColorPrimary: {
          backgroundColor: _colors.primaryblue,
        },
      },
    },
  },
});

export default defaultTheme;
