import { Middleware } from "redux";

import { isEmptyOrWhiteSpace } from "../../Helpers/stringHelper";
import ActionPayload from "../../Models/actionPayload";
import ServiceRequest from "../../Models/serviceRequest";
import { RootState } from "../reducer";
import getService from "../../Services/serviceFactory";
import { rdx_setIsMaintenanceWindow } from "../Slices/maintenanceSlice";
import { useDispatch } from "react-redux";

const serviceMiddleware: Middleware<{}, RootState> =
  ({ dispatch }) =>
  (innerDispatch) =>
  async (action: ActionPayload<ServiceRequest<any>>) => {
    //
    // We will only continue on with execution of this middleware if the action type is "service/callBegan", otherwise it will just pass it on through.
    if (action.type !== "service/callBegan") {
      return innerDispatch(action);
    }

    const {
      serviceName,
      serviceActionPath,
      useAnonymousAuth,
      method,
      data,
      headers,
      onStart,
      onSuccess,
      onError,
    } = action.payload;

    if (!isEmptyOrWhiteSpace(onStart)) {
      dispatch({ type: onStart });
    }

    try {
      const service = getService(serviceName);

      // TODO: validate that serviceActionPath starts with a forward slash
      // TODO: validate that the serviceActionPath isn't empty or null.

      let callHeaders = { ...headers };
      if (service.authToken && !useAnonymousAuth) {
        callHeaders["Authorization"] = `Bearer ${service.authToken}`;
      }

      const response = await service.request({
        url: `${service.baseUrl}${serviceActionPath}`,
        method,
        data,
        headers: callHeaders,
      });

      if (!isEmptyOrWhiteSpace(onSuccess)) {
        dispatch({
          payload: {
            data: response.data,
            requestPayload: { ...action.payload },
          },
          type: onSuccess,
        });
      }
    } catch (error) {
      if (error.response.data.status === 503) {
        dispatch({
          payload: { isMaintenanceWindow: true },
          type: "maintenance/setIsMaintenanceWindow",
        });
      }

      if (!isEmptyOrWhiteSpace(onError)) {
        dispatch({
          payload: { message: error.message },
          type: onError,
        });
      }
    }
  };

export default serviceMiddleware;
