import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Collapse, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  rdx_getLastResult,
  rdx_getPaymentInfo,
  rdx_resetLastResult,
} from "../../../Store/Slices/paymentSlice";
import { setSnackbarSettings } from "../../../Store/Slices/snackbarSlice";
import "../../Main/Main.scss";
import AddCardPaymentForm from "./AddCardPaymentForm";
import AddEFTPaymentForm from "./AddEFTPaymentForm";

interface AddPaymentMethodProps {
  setAddingPayment: React.Dispatch<React.SetStateAction<boolean>>;
  achPaymentEnabled: boolean;
}

const AddPaymentMethod = (props: AddPaymentMethodProps) => {
  const dispatch = useDispatch();
  const rdx_lastResult = useSelector(rdx_getLastResult);
  const rdx_existingPaymentInfo = useSelector(rdx_getPaymentInfo);
  const {achPaymentEnabled, setAddingPayment} = props;

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (rdx_lastResult) {
      if (rdx_lastResult.success) {
        setAddingPayment(false);
      } else {
        dispatch(
          setSnackbarSettings({
            message: rdx_lastResult?.message,
            severity: "error",
            open: true,
          })
        );
        setTimeout(() => {
          dispatch(rdx_resetLastResult());
        }, 1500);
      }
    }
  }, [rdx_lastResult, setAddingPayment, dispatch]);

  return (
    <>
      {!!setAddingPayment &&
        rdx_existingPaymentInfo &&
        rdx_existingPaymentInfo.length > 0 && (
          <Button
            variant="outlined"
            onClick={() => setAddingPayment(false)}
            sx={{ mb: 2 }}
          >
            Cancel
          </Button>
        )}

      <Tabs value={tabIndex} onChange={(_, val) => setTabIndex(val)} centered>
        <Tab
          label="Credit Card"
          icon={<FontAwesomeIcon icon={solid("credit-card")} />}
          iconPosition="start"
          sx={{ fontSize: "0.95rem" }}
        />
        <Tab
          label="Bank Account"
          icon={<FontAwesomeIcon icon={solid("bank")} />}
          iconPosition="start"
          sx={{ fontSize: "0.95rem" }}
        />
      </Tabs>

      <Collapse
        in={tabIndex === 0}
        className="textbox-width"
        sx={{ m: "auto" }}
      >
        <AddCardPaymentForm />
      </Collapse>

      <Collapse
        in={tabIndex === 1}
        className="textbox-width"
        sx={{ m: "auto" }}
      >
        <AddEFTPaymentForm achPaymentEnabled={achPaymentEnabled}/>
      </Collapse>
    </>
  );
};

export default AddPaymentMethod;
