
import React from "react";
import { Droppable } from "react-beautiful-dnd";
import DraggableItem from "./DraggableItem";

const DraggableList = ({ mappedItems }) => {

    return (
        <Droppable droppableId="droppablePayments">
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {mappedItems?.map((value, index) => {
                        return (
                            <DraggableItem
                                id={value.transactionInformationID}
                                index={index}
                                value={value}
                            />
                        );
                    })}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
};
export default DraggableList;
