import { brands, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch } from "react-redux";
import { Grid, IconButton } from "@mui/material";
import {
  rdx_removePaymentInfo,
} from "../../../Store/Slices/paymentSlice";

import detailedPaymentInfo from "../../../Models/detailedPaymentInfo";

interface PaymentMethodCardProps {
  paymentInfo: detailedPaymentInfo;
}

const PaymentMethodCard = (props: PaymentMethodCardProps) => {
  const dispatch = useDispatch();
  const {paymentInfo} = props

  const getPaymentTypeIcon = (paymentInfo: detailedPaymentInfo) => {
    switch (paymentInfo.paymentType) {
      case "EFT":
        return solid("building-columns");
      case "Visa":
        return brands("cc-visa");
      case "AmericanExpress":
        return brands("cc-amex");
      case "MasterCard":
        return brands("cc-mastercard");
      case "Discover":
        return brands("cc-discover");
      case "DinersClub":
        return brands("cc-diners-club");
      case "JCB":
        return brands("cc-jcb");
      default:
        return solid("credit-card");
    }
  };

  const handleRemove = (id: number) => () =>
    dispatch(rdx_removePaymentInfo(id));

  return (
    <>
      <Grid
        container
        alignItems="center"
        key={paymentInfo.transactionInformationID}
      >
        <Grid item xs={1}>
          <FontAwesomeIcon icon={solid("grip-dots-vertical")} size="2x" />
        </Grid>
        <Grid item xs={2}>
          <FontAwesomeIcon
            icon={getPaymentTypeIcon(paymentInfo)}
            size="2x"
          />
        </Grid>
        <Grid item xs={true}>
          ****{paymentInfo.maskAccountNumber}
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={handleRemove(paymentInfo.transactionInformationID)}
          >
            <FontAwesomeIcon icon={solid("trash")} />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentMethodCard;
