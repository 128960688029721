import axios from "axios";

import Service from "../Models/service";

const tenantCommunicationService = {
  request: axios.request,
  post: axios.post,
  baseUrl: process.env.REACT_APP_TENANTCOMMUNICATIONSERVICE_URL,
} as Service;

export default tenantCommunicationService;
