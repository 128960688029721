import axios from "axios";
import Service from "../Models/service";

const serviceEnvName = "TENANTREGISTRATIONAPI";

const tenantRegistrationApi = {
  request: axios.request,
  post: axios.post,
  baseUrl: process.env[`REACT_APP_${serviceEnvName}_URL`],
} as Service;

export default tenantRegistrationApi;
