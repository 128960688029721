import React from "react";
import { useSelector } from "react-redux";
import { AppBar, Box, Toolbar, Typography, Grid } from "@mui/material";

import { rdx_getLogoURL } from "../../Store/Slices/tenantRegistationSlice";
import MaintenanceBanner from "../Maintenance/MaintenanceBanner";
import { rdx_getmaintenanceBanner } from "../../Store/Slices/tenantRegistationSlice";

import "./Navbar.scss";

const devEnvType =
  process.env.REACT_APP_ENVIRONMENT === "development"
    ? "DEV"
    : process.env.REACT_APP_ENVIRONMENT === "staging"
    ? "STAGING"
    : "";

const Navbar = () => {
  // From Redux
  const rdx_logoURL = useSelector(rdx_getLogoURL);
  const rdx_isMaintenanceBanner = useSelector(rdx_getmaintenanceBanner);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="primary" className="app-bar">
          <Toolbar>
            <Box>
              <img className="navbar-logo" src={rdx_logoURL} />
            </Box>
            {devEnvType && (
              <Box sx={{ padding: 1 }} className={"test-env"}>
                <Typography>TEST {devEnvType}</Typography>
              </Box>
            )}
          </Toolbar>
        </AppBar>
      </Box>
      {rdx_isMaintenanceBanner ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <MaintenanceBanner />
        </Grid>
      ) : null}
    </>
  );
};

export default Navbar;
