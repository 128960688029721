import React from "react";
import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from "@mui/material";

const LinearProgressWithLabel = (
  props: LinearProgressProps & { value: number }
) => {
  return (
    <>
      <Box sx={{ alignItems: "center" }}>
        <Typography variant="body2" sx={{ fontWeight: 800 }}>{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%" }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
      </Box>
    </>
  );
};

export default LinearProgressWithLabel;
