import { StepsAvailable } from "../Types/availableSteps";
import ValidationResult from "./validationResult";

export default interface RegistrationStep {
  stepID: number;
  stepName: StepsAvailable;
  stepOrder: number;
  isLastStep: boolean;
  lastValidationResult: ValidationResult;
}

export const unknownStep = {
  stepID: 0,
  stepName: "UnkownStep",
  stepOrder: 1,
  isLastStep: true,
} as RegistrationStep;
