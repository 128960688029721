import React from "react";
import { Box, Button, Modal, Fade, Typography, Backdrop } from "@mui/material";
import TermsAndConditionsModalProps from "../../../Models/Props/termsAndConditionsModalProps";

const SmsTermsAndConditions = (props: TermsAndConditionsModalProps) => {
  const modalBoxStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: 1,
    maxHeight: "70vh",
    overflowY: "auto",
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.modalIsOpen}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") props.handleModalClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      disableEscapeKeyDown
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.modalIsOpen}>
        <Box sx={modalBoxStyle}>
          <Typography id="transition-modal-title" variant="h6">
            SMS Terms and Conditions
          </Typography>
          <Typography id="transition-modal-description" paragraph>
            By submitting this form, I acknowledge that the phone number
            provided is connected to a mobile device that is capable of
            receiving SMS, EMS, or MMS text messages.
          </Typography>
          <Typography id="transition-modal-description" paragraph>
            {props.companyName} will use this number for the sole purpose of
            providing information related to your utilities account. By
            submitting this form, you acknowledge that
          </Typography>
          <Typography id="transition-modal-description" paragraph>
            {props.companyName} may opt to send information about account
            balances, due dates, etc. through text communication. At any time
            you may opt out of receiving communication via text message by
            updating this option in your profile.
          </Typography>
          <Typography id="transition-modal-description" paragraph>
            Message and data rates may apply.
          </Typography>
          <Box textAlign="center">
            <Button
              variant="outlined"
              onClick={props.handleAcceptTerms}
              color="secondary"
              size="large"
              sx={{ mt: 2 }}
            >
              Accept Terms & Conditions
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default SmsTermsAndConditions;
