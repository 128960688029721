import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "../..";
import ActionPayload from "../../Models/actionPayload";
import { RootState } from "../reducer";

const slice = createSlice({
  name: "maintenance",
  initialState: {
    isMaintenanceWindow: false,
  },
  reducers: {
    setIsMaintenanceWindow: (draft, action: ActionPayload<boolean>) => {
      draft.isMaintenanceWindow = action.payload;
    },
  },
});
export const rdx_getIsMaintenanceWindow = (state: RootState) =>
  state.entities.maintenance.isMaintenanceWindow;
const { setIsMaintenanceWindow } = slice.actions;

export const rdx_setIsMaintenanceWindow =
  (isMaintenanceWindow: boolean): AppThunk =>
  (dispatch: AppDispatch) =>
    dispatch(setIsMaintenanceWindow(isMaintenanceWindow));

const maintenanceReducer = slice.reducer;

export default maintenanceReducer;
