import {
  Alert,
  Box,
  Button,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validatePhoneNumber } from "../../../Helpers/validationHelper";
import SendSmsVerificationCodeBody from "../../../Models/ServiceBody/sendSmsVerificationCodeBody";
import VerifySmsCodeBody from "../../../Models/ServiceBody/verifySmsCodeBody";
import {
  rdx_getIsCodeVerified,
  rdx_getIsVerificationMessageSent,
  rdx_sendSmsVerificationCode,
  rdx_verifySmsCode,
} from "../../../Store/Slices/smsVerificationSlice";
import { setSnackbarSettings } from "../../../Store/Slices/snackbarSlice";
import {
  rdx_clearStepErrorMessage,
  rdx_completeAuthorizeSmsStep,
} from "../../../Store/Slices/stepManagerSlice";
import { rdx_getCompanyName } from "../../../Store/Slices/tenantRegistationSlice";
import { AuthorizeSmsStatus } from "../../../Types/authorizeSmsStatus";
import Loading from "../../Loading/Loading";
import "../../Main/Main.scss";
import SmsTermsAndConditions from "./SmsTermsAndConditionsModal";

const AuthorizeSms = () => {
  // From Redux
  const dispatch = useDispatch();
  const rdx_isVerificationMessageSent = useSelector(
    rdx_getIsVerificationMessageSent
  );
  const rdx_isCodeVerified = useSelector(rdx_getIsCodeVerified);
  const rdx_companyName = useSelector(rdx_getCompanyName);

  // Local state
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const [smsCode, setSmsCode] = useState("");
  const [smsCodeError, setSmsCodeError] = useState(false);
  const [smsCodeErrorMessage, setSmsCodeErrorMessage] = useState("");
  const [codeControlStatus, setCodeControlStatus] =
    useState<AuthorizeSmsStatus>("idle");
  const [hasAgreedToTermsAndConditions, setHasAgreedToTermsAndConditions] =
    useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

  useEffect(() => {
    if (rdx_isVerificationMessageSent != null) {
      if (rdx_isVerificationMessageSent.success) {
        setCodeControlStatus("pending");

        setPhoneError(false);
        setPhoneErrorMessage("");
      } else {
        setCodeControlStatus("idle");
        if (rdx_isVerificationMessageSent.message === "Network Error") {
          dispatch(
            setSnackbarSettings({
              open: true,
              severity: "error",
              message: "Issue sending verification code",
            })
          );
          setTimeout(() => {
            dispatch(rdx_clearStepErrorMessage());
          }, 1500);
          setPhoneError(false);
          setPhoneErrorMessage("");
        } else {
          setPhoneError(true);
          setPhoneErrorMessage(rdx_isVerificationMessageSent.message);
        }
      }
    }
  }, [rdx_isVerificationMessageSent, dispatch]);

  useEffect(() => {
    if (rdx_isCodeVerified != null) {
      if (rdx_isCodeVerified.success) {
        setCodeControlStatus("success");
      } else {
        setCodeControlStatus("pending");

        setSmsCodeError(true);
        setSmsCodeErrorMessage(rdx_isCodeVerified.message);
      }
    }
  }, [rdx_isCodeVerified, dispatch]);

  const completeAuthorizeSmsStep = () => {
    dispatch(rdx_completeAuthorizeSmsStep());
  };

  const handlePhoneValueChanged = (
    e: string | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    let inputPhoneNumber = typeof e === "string" ? e : e.currentTarget.value;

    setPhoneNumber(inputPhoneNumber);

    let [isValid, errorMessage] = validatePhoneNumber(inputPhoneNumber);
    setPhoneError(!isValid);
    setPhoneErrorMessage(errorMessage);

    //TODO: If we don't wanna show the error as there typing.
    // if (phoneError) {
    //   setPhoneError(false);
    //   setPhoneErrorMessage("");
    // }
  };

  const handleSmsCodeValueChanged = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const inputSmsCode = e.currentTarget.value;
    setSmsCode(inputSmsCode);
  };

  const handleVerifyClick = () => {
    let [isValid, errorMessage] = validatePhoneNumber(phoneNumber);

    if (isValid) {
      setCodeControlStatus("processing");

      dispatch(
        rdx_sendSmsVerificationCode({
          toPhoneNumber: phoneNumber,
        } as SendSmsVerificationCodeBody)
      );
    } else {
      setPhoneError(true);
      setPhoneErrorMessage(errorMessage);
    }
  };

  const handleSmsCodeSubmit = () => {
    //TODO - validate that the sms code entered is in a valid shape? This may not be wanted
    dispatch(
      rdx_verifySmsCode({
        code: smsCode,
      } as VerifySmsCodeBody)
    );
  };

  const handleCheckboxClick = () => {
    if (hasAgreedToTermsAndConditions) {
      setHasAgreedToTermsAndConditions(false);
    } else {
      handleShowTermsAndConditions();
    }
  };

  const acceptsTermsAndConditions = () => {
    setHasAgreedToTermsAndConditions(true);
    handleHideTermsAndConditions();
  };

  const handleShowTermsAndConditions = () => setShowTermsAndConditions(true);
  const handleHideTermsAndConditions = () => setShowTermsAndConditions(false);

  return (
    <>
      <CardContent>
        <Box>
          <Typography m={3} variant="h6" gutterBottom>
            Sign up for text reminders
          </Typography>
          <Typography m={3} gutterBottom>
            We’ll notify you when your bill is due, when your AutoPay will
            process, and whether your payment was successful.
          </Typography>
          <Box>
            <MuiPhoneNumber
              id="phone-input"
              label="Mobile Phone #"
              variant="outlined"
              className="textbox-width"
              defaultCountry={"us"}
              onlyCountries={["us", "ca"]}
              disableAreaCodes={true}
              disableDropdown={true}
              onChange={handlePhoneValueChanged}
              value={phoneNumber}
              error={phoneError}
              helperText={phoneErrorMessage}
              disabled={codeControlStatus === "success"}
            />
          </Box>

          {codeControlStatus === "idle" && (
            <Button
              variant="contained"
              onClick={handleVerifyClick}
              color="secondary"
              size="large"
              sx={{ marginTop: 3 }}
            >
              VERIFY
            </Button>
          )}
          {codeControlStatus === "pending" && (
            <Box mt={2}>
              <TextField
                id="code-input"
                label="Code"
                variant="outlined"
                className="textbox-width"
                onChange={handleSmsCodeValueChanged}
                value={smsCode}
                inputProps={{ maxLength: 6 }}
                error={smsCodeError}
                helperText={smsCodeErrorMessage}
              />
              <Box>
                <Button
                  variant="outlined"
                  onClick={handleVerifyClick}
                  color="primary"
                  size="large"
                  sx={{ marginTop: 2, marginRight: 2 }}
                >
                  RESEND
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSmsCodeSubmit}
                  color="secondary"
                  size="large"
                  sx={{ marginTop: 2 }}
                >
                  SUBMIT
                </Button>
              </Box>
            </Box>
          )}
          {codeControlStatus === "processing" && (
            <Box pt={2}>
              <Loading />
            </Box>
          )}
          {codeControlStatus === "success" && (
            <Alert
              severity="success"
              sx={{
                marginTop: 4,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              The Mobile Phone # provided has been verified.
            </Alert>
          )}

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowSpacing={2}
            p={2}
            sx={{ marginTop: 2 }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasAgreedToTermsAndConditions}
                    onClick={handleCheckboxClick}
                  />
                }
                label="I have read the Terms & Conditions and authorize Conservice to send
            me SMS messages. Standard data/message rates apply."
              />
            </FormGroup>
          </Grid>

          <Box>
            <Button
              variant="outlined"
              color="primary"
              sx={{ marginTop: 2 }}
              onClick={handleShowTermsAndConditions}
            >
              TERMS & CONDITIONS
            </Button>
          </Box>

          <SmsTermsAndConditions
            modalIsOpen={showTermsAndConditions}
            handleModalClose={handleHideTermsAndConditions}
            handleAcceptTerms={acceptsTermsAndConditions}
            companyName={rdx_companyName}
          />

          {codeControlStatus !== "idle" && (
            <Box my={4}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={completeAuthorizeSmsStep}
                disabled={
                  !hasAgreedToTermsAndConditions ||
                  codeControlStatus !== "success"
                }
              >
                Next
              </Button>
            </Box>
          )}
          {codeControlStatus === "idle" && (
            <Box textAlign="center" my={3}>
              <Button
                variant="text"
                onClick={completeAuthorizeSmsStep}
                color="secondary"
                size="large"
              >
                SKIP
              </Button>
            </Box>
          )}
        </Box>
      </CardContent>
    </>
  );
};

export default AuthorizeSms;
