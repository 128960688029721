import React from "react";
import { useSelector } from "react-redux";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CardContent, Typography } from "@mui/material";

import { rdx_getCustomerServicePhone } from "../../../Store/Slices/tenantRegistationSlice";
import { rdx_getFailedAttemptCount } from "../../../Store/Slices/stepManagerSlice";

function ContactCustomerService() {
  // From Redux
  const rdx_customerServicePhone = useSelector(rdx_getCustomerServicePhone);
  const rdx_failedAttemptCount = useSelector(rdx_getFailedAttemptCount);

  return (
    <>
      <Typography color="error" my={3}>
        <FontAwesomeIcon icon={solid("hexagon-exclamation")} size="5x" />
      </Typography>
      <Typography variant="h4" mb={3}>
        Contact Customer Service
      </Typography>
      <CardContent>
        {rdx_failedAttemptCount > 2 ? (
          <Typography>
            The allowed number of attempts for registering your account has been
            exceeded.
          </Typography>
        ) : (
          <Typography sx={{ fontSize: "h6.fontSize" }}>
            An error occured.
          </Typography>
        )}
        <Typography mt={2}>Please contact customer service at</Typography>
        <Button
          size="large"
          color="primary"
          startIcon={<FontAwesomeIcon icon={solid("phone")} />}
          href={"tel:" + rdx_customerServicePhone}
        >
          {rdx_customerServicePhone}
        </Button>
      </CardContent>
    </>
  );
}

export default ContactCustomerService;
