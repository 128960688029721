import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  luhnCheck,
  nonAsciiCommasCheck,
} from "../../../Helpers/validationHelper";
import NameValueItem from "../../../Models/nameValueItem";
import SaveCardInfoBody from "../../../Models/ServiceBody/saveCardInfoBody";
import {
  rdx_getPaymentInfo,
  rdx_saveCardInfo,
} from "../../../Store/Slices/paymentSlice";
import "../../Main/Main.scss";

const monthOptions = [
  { name: "01 - January", value: "01" },
  { name: "02 - February", value: "02" },
  { name: "03 - March", value: "03" },
  { name: "04 - April", value: "04" },
  { name: "05 - May", value: "05" },
  { name: "06 - June", value: "06" },
  { name: "07 - July", value: "07" },
  { name: "08 - August", value: "08" },
  { name: "09 - September", value: "09" },
  { name: "10 - October", value: "10" },
  { name: "11 - November", value: "11" },
  { name: "12 - December", value: "12" },
] as NameValueItem[];

const today = new Date();
const baseMonth = today.getMonth() + 1;
const baseYear = today.getFullYear();
const yearOptions = Array.from(Array(10)).map((_, index) =>
  (index + baseYear).toString()
);

const AddCardPaymentForm = () => {
  const dispatch = useDispatch();
  const rdx_existingPaymentInfo = useSelector(rdx_getPaymentInfo);

  const [dupeConfirmOpen, setDupeConfirmOpen] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<SaveCardInfoBody>({ mode: "all" });

  const duplicateCheck = (cardNumber: string) => {
    return (
      rdx_existingPaymentInfo.find(
        (value) =>
          value.maskAccountNumber ===
            cardNumber.substring(cardNumber.length - 4) &&
          value.paymentType !== "EFT"
      ) != null
    );
  };

  const onSubmit: SubmitHandler<SaveCardInfoBody> = (data) => {
    if (duplicateCheck(data.cardNumber)) {
      setDupeConfirmOpen(true);
    } else {
      dispatch(rdx_saveCardInfo(data));
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Name on Card"
          className={"textbox-width"}
          autoComplete="off"
          sx={{ mt: 2 }}
          error={!!errors.nameOnCard}
          inputProps={{ maxLength: 75 }}
          helperText={errors.nameOnCard ? errors.nameOnCard?.message : ""}
          {...register("nameOnCard", {
            required: "Name on Card is required",
            maxLength: {
              value: 75,
              message: "Name exceeds maximum length",
            },
            validate: (nameOnCard) => nonAsciiCommasCheck(nameOnCard),
          })}
        />
        <TextField
          label="Card Number"
          className={"textbox-width"}
          autoComplete="off"
          inputProps={{ maxLength: 19 }}
          sx={{ mt: 2 }}
          error={!!errors.cardNumber}
          helperText={errors.cardNumber ? errors.cardNumber?.message : ""}
          {...register("cardNumber", {
            required: "Card Number is required",
            pattern: {
              value: /^\d{12,19}$/,
              message: "Invalid Card Number",
            },
            maxLength: {
              value: 19,
              message: "Invalid Card Number",
            },
            validate: (cardNumber) => luhnCheck(cardNumber),
          })}
        />
        <FormGroup>
          <TextField
            select
            label="Expiration Month"
            className={"textbox-width"}
            defaultValue=""
            sx={{ textAlign: "left", mt: 2 }}
            inputProps={register("expirationMonth", {
              required: "Expiration Month is required",
              validate: (month) => {
                let errorMessage = null;
                let monthNumber = parseInt(month);
                if (monthNumber < baseMonth) {
                  let values = getValues();
                  let yearNumber = parseInt(values.expirationYear);

                  if (yearNumber === baseYear) {
                    errorMessage = "Expiration Date is in the past";
                  }
                }

                return errorMessage;
              },
              onChange: (e) =>
                setValue("expirationMonth", e.target.value, {
                  shouldValidate: true,
                }),
            })}
            error={!!errors.expirationMonth}
            helperText={errors.expirationMonth?.message}
          >
            {monthOptions.map((option) => (
              <MenuItem key={option.name} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Expiration Year"
            className={"textbox-width"}
            defaultValue=""
            sx={{ textAlign: "left", mt: 2 }}
            inputProps={register("expirationYear", {
              required: "Expiration Year is required",
              validate: (year) => {
                let errorMessage = null;
                let yearNumber = parseInt(year);
                if (yearNumber === baseYear) {
                  let values = getValues();
                  let monthNumber = parseInt(values.expirationMonth);

                  if (monthNumber < baseMonth) {
                    errorMessage = "Expiration Date is in the past";
                  }
                }

                return errorMessage;
              },
              onChange: (e) =>
                setValue("expirationYear", e.target.value, {
                  shouldValidate: true,
                }),
            })}
            error={!!errors.expirationYear}
            helperText={errors.expirationYear?.message}
          >
            {yearOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </FormGroup>
        <TextField
          label="Billing Address"
          className={"textbox-width"}
          autoComplete="off"
          sx={{ mt: 2 }}
          inputProps={{ maxLength: 100 }}
          error={!!errors.billingAddress}
          helperText={
            errors.billingAddress ? errors.billingAddress?.message : ""
          }
          {...register("billingAddress", {
            required: "Billing Address is required",
            maxLength: {
              value: 100,
              message: "Billing Address exceeds max length",
            },
            validate: (billingAddress) => nonAsciiCommasCheck(billingAddress),
          })}
        />
        <TextField
          label="Billing City"
          className={"textbox-width"}
          autoComplete="off"
          sx={{ mt: 2 }}
          inputProps={{ maxLength: 40 }}
          error={!!errors.billingCity}
          helperText={errors.billingCity ? errors.billingCity?.message : ""}
          {...register("billingCity", {
            required: "Billing City is required",
            maxLength: {
              value: 40,
              message: "Billing City exceeds maximum length",
            },
            validate: (billingCity) => nonAsciiCommasCheck(billingCity),
          })}
        />
        <TextField
          label="Billing State"
          className={"textbox-width"}
          autoComplete="off"
          sx={{ mt: 2 }}
          inputProps={{ maxLength: 2 }}
          error={!!errors.billingState}
          helperText={errors.billingState ? errors.billingState?.message : ""}
          {...register("billingState", {
            required: "Billing State Code is required",
            maxLength: {
              value: 2,
              message:
                "Billing State should be the 2 letter code for your state",
            },
            validate: (billingState) => nonAsciiCommasCheck(billingState),
          })}
        />

        <TextField
          label="Billing Zip/Postal Code"
          className={"textbox-width"}
          autoComplete="off"
          inputProps={{ maxLength: 7 }}
          sx={{ mt: 2 }}
          error={!!errors.billingPostalCode}
          helperText={
            errors.billingPostalCode ? errors.billingPostalCode?.message : ""
          }
          {...register("billingPostalCode", {
            required: "Billing Zip/Postal Code is required",
            maxLength: {
              value: 7,
              message: "Invalid Zip/Postal Code",
            },
            pattern: {
              value:
                /(^\d{5}(-\d{4})?$)|(^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]{1}\d{1}[ABCEGHJKLMNPRSTVWXYZabceghjklmnprstv‌​xy]{1} ?\d{1}[ABCEGHJKLMNPRSTVWXYZabceghjklmnprstvxy]{1}\d{1}$)/,
              message: "Invalid Zip/Postal Code",
            },
          })}
        />
        <TextField
          label="Email Confirmation"
          className={"textbox-width"}
          autoComplete="off"
          sx={{ mt: 2 }}
          inputProps={{ maxLength: 75 }}
          error={!!errors.confirmationEmailAddress}
          helperText={
            errors.confirmationEmailAddress
              ? errors.confirmationEmailAddress?.message
              : ""
          }
          {...register("confirmationEmailAddress", {
            required: "Confirmation Email is required",
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,9}$/,
              message: "Invalid Email Address",
            },
            maxLength: {
              value: 75,
              message: "Email address exceeds maximum length",
            },
          })}
        />

        <Button
          type="submit"
          sx={{ mt: 2 }}
          variant="contained"
          color="secondary"
          size="large"
        >
          Submit
        </Button>

        <Dialog
          open={dupeConfirmOpen}
          onClose={() => setDupeConfirmOpen(false)}
        >
          <DialogTitle id="alert-dialog-title">
            {"Possible Duplicate"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              The provided card number may be a duplicate of an existing payment
              method. Do you still want to add it?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDupeConfirmOpen(false)}>Cancel</Button>
            <Button
              onClick={handleSubmit((obj) => {
                setDupeConfirmOpen(false);
                dispatch(rdx_saveCardInfo(obj));
              })}
              autoFocus
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </>
  );
};

export default AddCardPaymentForm;
