import {
  Alert,
  Box,
  Button,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmptyOrWhiteSpace } from "../../../Helpers/stringHelper";
import {
  rdx_getIsLoading,
  rdx_getStepValidationResult,
  rdx_completeReviewTermsStep,
} from "../../../Store/Slices/stepManagerSlice";
import TermsAndConditionsModal from "./TermsAndConditionsModal";
import Loading from "../../Loading/Loading";

const ReviewTerms = () => {
  // From redux
  const dispatch = useDispatch();
  const rdx_stepValidationResult = useSelector(
    rdx_getStepValidationResult("ReviewTerms")
  );
  const rdx_IsLoading = useSelector(rdx_getIsLoading);

  const [termsOpen, setTermsOpen] = useState(false);

  const handleTermsOpen = () => setTermsOpen(true);
  const handleTermsClose = () => setTermsOpen(false);

  const [termsAgreed, setTermsAgreed] = useState(false);
  const handleTermsCheckboxClick = () => {
    if (termsAgreed) {
      setTermsAgreed(false);
    } else {
      handleTermsOpen();
    }
  };

  const [agreedToAutoPay, setAgreedToAutoPay] = useState(true);
  const toggleAutoPayPreference = () => setAgreedToAutoPay(!agreedToAutoPay);

  const handleAcceptTerms = () => {
    setTermsAgreed(true);
    handleTermsClose();
  };

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (
      !rdx_stepValidationResult.success &&
      !isEmptyOrWhiteSpace(rdx_stepValidationResult.message)
    ) {
      setShowError(true);
      setErrorMessage(rdx_stepValidationResult.message);
    }
  }, [rdx_stepValidationResult, agreedToAutoPay]);

  const nextButtonClick = () => {
    dispatch(rdx_completeReviewTermsStep(agreedToAutoPay));
    //The result of this dispatch is handled in the useEffect
  };

  return (
    <>
      <CardContent>
        {rdx_IsLoading && <Loading />}
        {!rdx_IsLoading && (
          <>
            <Typography mb={5} mt={2} variant="h6" gutterBottom>
              Review Terms & Conditions
            </Typography>
            <Button
              onClick={handleTermsOpen}
              variant="contained"
              color="secondary"
              size="large"
            >
              TERMS & CONDITIONS
            </Button>

            <TermsAndConditionsModal
              modalIsOpen={termsOpen}
              handleModalClose={handleTermsClose}
              handleAcceptTerms={handleAcceptTerms}
            />

            <Grid container justifyContent="center" pt={3}>
              <Box>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={termsAgreed}
                        onClick={handleTermsCheckboxClick}
                      />
                    }
                    label="I have read and agree to the Terms & Conditions"
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={agreedToAutoPay}
                        onClick={toggleAutoPayPreference}
                      />
                    }
                    label="I agree to participate in AutoPay"
                  />
                </FormGroup>
              </Box>
            </Grid>

            {showError && <Alert severity="error">{errorMessage}</Alert>}

            <Box textAlign="center" my={3}>
              <Button
                variant="contained"
                onClick={nextButtonClick}
                color="secondary"
                size="large"
                disabled={!termsAgreed}
              >
                Next
              </Button>
            </Box>
          </>
        )}
      </CardContent>
    </>
  );
};

export default ReviewTerms;
