import { Box, Button, CardContent, FormGroup, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  rdx_getIsInitialized,
  rdx_getIsLoading,
  rdx_getNeedsRefresh,
  rdx_getPaymentInfo,
  rdx_loadDetailedPaymentInfo,
  rdx_reorderPaymentInfo,
  rdx_resetLastResult,
} from "../../../Store/Slices/paymentSlice";
import { rdx_completePaymentMethodsStep } from "../../../Store/Slices/stepManagerSlice";
import Loading from "../../Loading/Loading";
import AddPaymentMethod from "./AddPaymentMethod";
import DetailedPaymentInfo from "../../../Models/detailedPaymentInfo";
import { DragDropContext } from "react-beautiful-dnd";
import DraggableList from "../../HelperComponents/DraggableList";

const PaymentMethods = () => {
  // From Redux
  const dispatch = useDispatch();
  const rdx_paymentInfo = useSelector(rdx_getPaymentInfo);
  const rdx_isInitialized = useSelector(rdx_getIsInitialized);
  const rdx_isLoading = useSelector(rdx_getIsLoading);
  const rdx_needsRefresh = useSelector(rdx_getNeedsRefresh);
  
  const [achPaymentEnabled, setAchPaymentEnabled] = useState(true);
  const [addingPayment, setAddingPayment] = useState(false);
  const [paymentInfoCards, setPaymentInfoCards] = useState(
    [] as DetailedPaymentInfo[]
  );

  useEffect(() => {
    const achPaymentExists = rdx_paymentInfo.find((p) => p.paymentType === 'EFT');
    setPaymentInfoCards(rdx_paymentInfo);
    
    achPaymentExists ? setAchPaymentEnabled(false) : setAchPaymentEnabled(true);
    
  }, [rdx_paymentInfo]);

  useEffect(() => {
    if (rdx_needsRefresh) {
      dispatch(rdx_loadDetailedPaymentInfo());
    }
  }, [rdx_needsRefresh, dispatch]);

  useEffect(() => {
    if (rdx_isInitialized) {
      setAddingPayment(false);
    }
  }, [rdx_isInitialized]);

  const nextButtonClick = () => {
    dispatch(rdx_reorderPaymentInfo(paymentInfoCards));
    dispatch(rdx_completePaymentMethodsStep());
  };

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newCardIndexs = Array.from(paymentInfoCards)
    const [removed] = newCardIndexs.splice(source.index, 1);
    newCardIndexs.splice(destination.index, 0, removed);

    setPaymentInfoCards(newCardIndexs);
  };

  const newPaymentMethodOnClick = () => {
    dispatch(rdx_reorderPaymentInfo(paymentInfoCards));
    setAddingPayment(true);
    dispatch(rdx_resetLastResult());
  };

  return (
    <>
      <CardContent>
        <Typography mb={5} mt={2} variant="h6" gutterBottom>
          Add Payment Methods
        </Typography>
        <Box textAlign="center" my={3}>
          {rdx_isLoading && <Loading />}
          {!paymentInfoCards?.length && !rdx_isLoading && rdx_isInitialized && (
            <p>You must add at least one payment method to proceed.</p>
          )}
          {!rdx_isLoading && (addingPayment || !paymentInfoCards?.length) && (
            <AddPaymentMethod setAddingPayment={setAddingPayment} achPaymentEnabled={achPaymentEnabled} />
          )}
          {!rdx_isLoading && !(addingPayment || !paymentInfoCards?.length) && (
            <>
              <p>
                Please drag & drop to rank the order in which you which you
                would like us to run your payment methods for Auto Pay and
                Automatic Late Pay.
              </p>
              <p>
                If your top payment method fails, we will run the next payment
                method in the list.
              </p>
              <DragDropContext onDragEnd={onDragEnd}>
                <DraggableList mappedItems={paymentInfoCards} />
              </DragDropContext>
              <FormGroup sx={{ m: "auto", mb: 6 }}>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={newPaymentMethodOnClick}
                >
                  + NEW PAYMENT METHOD
                </Button>
              </FormGroup>
              <Button
                variant="contained"
                onClick={nextButtonClick}
                color="secondary"
                size="large"
                disabled={!paymentInfoCards?.length}
              >
                Next
              </Button>
            </>
          )}
        </Box>
      </CardContent>
    </>
  );
};

export default PaymentMethods;
