
import { Card } from "@mui/material";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import PaymentMethodCard from "../Steps/PaymentMethods/PaymentMethodCard";

const DraggableItem = ({ id, index, value }) => {

  return (
    <Draggable key={id} draggableId={id.toString()} index={index}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Card
            sx={{
              mb: 1,
              p: 1,
              ":hover": {
                boxShadow: 20, // theme.shadows[20]
              },
              ":active": {
                boxShadow: 20, // theme.shadows[20]
              },
              opacity: snapshot.isDragging ? 0.7 : 1,
              background: snapshot.isDragging ? "#555759" : "#D3D3D3",
            }}
          >
            <PaymentMethodCard
              paymentInfo={value}
            />
          </Card>

        </div>
      )}
    </Draggable>
  );
};
export default DraggableItem;
