import {
  Button,
  CardContent,
  Chip,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  validatePassword,
  validatePasswordOne,
  validatePasswordTwo,
  validateUsername,
} from "../../../Helpers/validationHelper";
import ServiceValidationResult from "../../../Models/serviceValidationResult";
import getService from "../../../Services/serviceFactory";
import {
  rdx_getIsLoading,
  rdx_getStepValidationResult,
  rdx_loadSetUpAccountAccess,
} from "../../../Store/Slices/stepManagerSlice";
import {
  rdx_getNewToken,
  rdx_setUsername,
} from "../../../Store/Slices/tenantRegistationSlice";
import Loading from "../../Loading/Loading";
import "../../Main/Main.scss";

const SetUpAccountAccess = () => {
  // From Redux
  const dispatch = useDispatch();
  const rdx_newToken = useSelector(rdx_getNewToken);
  const rdx_stepValidationResult = useSelector(
    rdx_getStepValidationResult("SetUpAccountAccess")
  );
  const rdx_IsLoading = useSelector(rdx_getIsLoading);
  // Local State
  const [username, setUsername] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [userNameErr, setUserNameErr] = useState(false);
  const [userNameMsg, setUserNameMsg] = useState("");
  const [password1Err, setPassword1Err] = useState(false);
  const [passwordMsg1, setPassword1Msg] = useState("");
  const [password2Err, setPassword2Err] = useState(false);
  const [passwordMsg2, setPassword2Msg] = useState("");

  const handleUsernameValueChanged = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    let username = e.currentTarget.value;
    let [isValid, validationMessage] = validateUsername(username);

    setUsername(username);
    setUserNameErr(!isValid);
    setUserNameMsg(isValid ? "" : validationMessage);

    if (isValid) {
      checkUsernameAvailability(username);
    }
  };

  async function checkUsernameAvailability(username: string) {
    var service = getService("tenantRegistrationApi");

    const request = await service.post(
      `${service.baseUrl}/Registration/UsernameAvailable`,
      { token: rdx_newToken, username },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${service.authToken}`,
          AppName: "TenantRegistration",
        },
      }
    );

    let validationResult = request.data as ServiceValidationResult;
    setUserNameErr(!validationResult.success);
    setUserNameMsg(validationResult.success ? "" : validationResult.message);

    return request;
  }

  useEffect(() => {
    if (
      !rdx_stepValidationResult.success &&
      rdx_stepValidationResult.message !== ""
    ) {
      setPassword1Err(true);
      setPassword1Msg(rdx_stepValidationResult.message);
    }
  }, [rdx_stepValidationResult]);

  const handlePassword1ValueChanged = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    let password = e.currentTarget.value;
    let [isValid, validationMessage] = validatePasswordOne(password);

    setPassword1(password);
    setPassword1Err(!isValid);
    setPassword1Msg(isValid ? "" : validationMessage);
  };

  const handlePassword2ValueChanged = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    let password = e.currentTarget.value;
    let [isValid, validationMessage] = validatePasswordTwo(password, password1);

    setPassword2(password);
    setPassword2Err(!isValid);
    setPassword2Msg(isValid ? "" : validationMessage);
  };

  const nextButtonClick = () => {
    let [isValidPassword, passwordMessage] = validatePassword(
      password1,
      password2
    );

    let [isValidUsername, usernameMessage] = validateUsername(username);

    if (!isValidPassword) {
      setPassword1Err(true);
      setPassword1Msg(passwordMessage);
    } else if (!isValidUsername) {
      setUserNameErr(true);
      setUserNameMsg(usernameMessage);
    } else {
      dispatch(rdx_loadSetUpAccountAccess(username, password1));
      dispatch(rdx_setUsername(username));
      setPassword1Err(rdx_stepValidationResult.success);
      setPassword1Msg(rdx_stepValidationResult.message);
    }
  };

  return (
    <CardContent>
      <Grid
        container
        direction="row"
        mb={3}
        mt={2}
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h6" gutterBottom>
          Set up account access
        </Typography>
      </Grid>
      {rdx_IsLoading && <Loading />}
      {!rdx_IsLoading && (
        <>
          {" "}
          <Divider>
            <Chip label="Create Username" variant="outlined" />
          </Divider>
          <Grid
            container
            mt={2}
            mb={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography sx={{ fontSize: "1rem" }} gutterBottom>
              Usernames must be between 5 and 15 characters, contain at least
              one letter, and may not contain special characters.
            </Typography>
          </Grid>
          <Grid
            container
            mt={3}
            mb={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <TextField
              id="outlined-basic"
              label="New Username"
              variant="outlined"
              className={"textbox-width"}
              autoComplete="off"
              inputProps={{ maxLength: 15 }}
              onChange={handleUsernameValueChanged}
              value={username}
              error={userNameErr}
              helperText={userNameMsg}
            />
          </Grid>
          <Divider>
            <Chip label="Create Password" variant="outlined" />
          </Divider>
          <Grid
            container
            mt={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography sx={{ fontSize: "1rem" }} gutterBottom>
              The password must be at least eight characters in length and
              contain an upper case character, lower case character, number, and
              special character such as ! # $ % &.
            </Typography>
          </Grid>
          <Grid
            container
            mt={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <TextField
              id="new-password-input"
              label="New Password"
              type="password"
              autoComplete="current-password"
              variant="outlined"
              className={"textbox-width"}
              inputProps={{ maxLength: 75 }}
              onChange={handlePassword1ValueChanged}
              error={password1Err}
              helperText={passwordMsg1}
            />
          </Grid>
          <Grid
            container
            mt={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <TextField
              id="new-password-input-2"
              label="Confirm Password"
              type="password"
              autoComplete="current-password"
              variant="outlined"
              className={"textbox-width"}
              inputProps={{ maxLength: 75 }}
              onChange={handlePassword2ValueChanged}
              error={password2Err}
              helperText={passwordMsg2}
            />
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            mt={3}
            mb={3}
          >
            <Button
              variant="contained"
              onClick={nextButtonClick}
              color="secondary"
              size="large"
            >
              Next
            </Button>
          </Grid>
        </>
      )}
    </CardContent>
  );
};

export default SetUpAccountAccess;
