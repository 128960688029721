import { isEmptyOrWhiteSpace } from "./stringHelper";

const usernameRegex = /^(?![0-9]*$)[a-zA-Z0-9]+$/;
const hasLowerCase = /[a-z]/;
const hasNumbers = /[0-9]/;
const hasSpecialCharacter = /[^a-zA-Z0-9]/;
const hasUpperCase = /[A-Z]/;
const alphaNumericRegex = /^[a-zA-Z0-9]+$/;
const hasNonAsciiCharacters = /[^\u0000-\u007f]/;
const hasCommas = /,(?=[^,]*$)/;

export const validatePin = (pin: string): [boolean, string] => {
  let errorMessage: string;

  if (isEmptyOrWhiteSpace(pin)) {
    errorMessage = "Pin cannot be blank.";
  } else if (pin.length !== 4) {
    errorMessage = "Pin is not the correct length.";
  } else if (!alphaNumericRegex.test(pin)) {
    errorMessage = "Pin must be just numbers and letters.";
  } else {
    errorMessage = "";
  }

  return [isEmptyOrWhiteSpace(errorMessage), errorMessage];
};

export const validateAddress = (address: string): [boolean, string] => {
  let errorMessage: string;

  if (isEmptyOrWhiteSpace(address)) {
    errorMessage = "Address cannot be blank.";
  } else if (address.length > 100) {
    errorMessage = "Address has reached max characters.";
  } else {
    errorMessage = "";
  }

  return [isEmptyOrWhiteSpace(errorMessage), errorMessage];
};

export const validateUsername = (username: string): [boolean, string] => {
  let errorMessage: string;

  if (isEmptyOrWhiteSpace(username)) {
    errorMessage = "New username cannot be blank.";
  } else if (username.length < 5 || username.length > 15) {
    errorMessage = "Username must be between 5 and 15 characters.";
  } else if (!usernameRegex.test(username)) {
    errorMessage =
      "Username must contain at least one letter and no special characters.";
  } else {
    errorMessage = "";
  }

  return [isEmptyOrWhiteSpace(errorMessage), errorMessage];
};

export const validatePasswordOne = (password: string): [boolean, string] => {
  let errorMessage: string;
  //check if password is between 8 and 25 characters long.
  if (password.length < 8 || password.length > 25) {
    errorMessage =
      "The password must be between 8 characters and 25 characters long.";
  }

  //check for one uppercase.
  else if (!password.match(hasUpperCase)) {
    errorMessage = "The password must contain one uppercase letter.";
  }

  //check for one lowercase.
  else if (!password.match(hasLowerCase)) {
    errorMessage = "The password must contain one lowercase letter.";
  }

  //check for one number.
  else if (!password.match(hasNumbers)) {
    errorMessage = "The password must contain one number.";
  }

  //check for special characters
  else if (password.match(hasSpecialCharacter) === null) {
    errorMessage =
      "The password must contain one special character such as ! # $ % &";
  } else {
    errorMessage = "";
  }

  return [isEmptyOrWhiteSpace(errorMessage), errorMessage];
};

export const validatePasswordTwo = (
  password: string,
  newPassword: string
): [boolean, string] => {
  let errorMessage: string;
  //check if password entries match
  if (password !== newPassword) {
    errorMessage = "The submitted passwords don't match.";
  } else {
    errorMessage = "";
  }

  return [isEmptyOrWhiteSpace(errorMessage), errorMessage];
};

export const validatePassword = (
  password: string,
  newPassword: string
): [boolean, string] => {
  let errorMessage: string;
  //check if password entries match
  if (password !== newPassword) {
    errorMessage = "The submitted passwords don't match.";
  }

  //check if password is between 8 and 25 characters long.
  else if (password.length < 8 || password.length > 25) {
    errorMessage =
      "The password must be between 8 characters and 25 characters long.";
  }

  //check for one uppercase.
  else if (!password.match(hasUpperCase)) {
    errorMessage = "The password must contain one uppercase letter.";
  }

  //check for one lowercase.
  else if (!password.match(hasLowerCase)) {
    errorMessage = "The password must contain one lowercase letter.";
  }

  //check for one number.
  else if (!password.match(hasNumbers)) {
    errorMessage = "The password must contain one number.";
  }

  //check for special characters
  else if (password.match(hasSpecialCharacter) === null) {
    errorMessage =
      "The password must contain one special character such as ! # $ % &";
  } else {
    errorMessage = "";
  }

  return [isEmptyOrWhiteSpace(errorMessage), errorMessage];
};

export const validatePhoneNumber = (phoneNumber: string): [boolean, string] => {
  const cleanPhoneNumber: string = phoneNumber
    .replace(" ", "")
    .replace("(", "")
    .replace(")", "")
    .replace("-", "")
    .replace(".", "")
    .replace(" ", "");

  let errorMessage: string;

  if (isEmptyOrWhiteSpace(cleanPhoneNumber)) {
    errorMessage = "Phone number cannot be blank.";
  } else if (!/[+]\d{11}/.test(cleanPhoneNumber)) {
    errorMessage = "Phone number not in format (xxx) xxx-xxxx";
  } else {
    errorMessage = "";
  }

  return [isEmptyOrWhiteSpace(errorMessage), errorMessage];
};

// From https://gist.github.com/DiegoSalazar/4075533
// Other option would be this one: https://gist.github.com/ShirtlessKirk/2134376
export const luhnCheck = (creditCardNumber: string) => {
  let nCheck = 0;
  let bEven = false;

  for (var n = creditCardNumber.length - 1; n >= 0; n--) {
    let cDigit = creditCardNumber.charAt(n);
    let nDigit = parseInt(cDigit, 10);

    if (bEven && (nDigit *= 2) > 9) {
      nDigit -= 9;
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 === 0 ? null : "Invalid Card Number";
};

export const validateEftRoutingNumber = (eftRoutingNumber: string) => {
  const numberArray = Array.from(eftRoutingNumber).map(
    (value) => value.charCodeAt(0) - "0".charCodeAt(0)
  );

  let result: boolean;

  if (numberArray.length !== 9) {
    result = false;
  } else if (numberArray[0] === 0) {
    result = true;
  } else {
    const checkSumValue =
      (7 * (numberArray[0] + numberArray[3] + numberArray[6]) +
        3 * (numberArray[1] + numberArray[4] + numberArray[7]) +
        9 * (numberArray[2] + numberArray[5])) %
      10;
    result = checkSumValue === numberArray[8];
  }
  return result ? null : "Invalid Routing Number";
};

export const nonAsciiCommasCheck = (input: string) => {
  if (hasNonAsciiCharacters.test(input)) {
    return "Commas and special characters are not allowed";
  }

  if (hasCommas.test(input)) {
    return "Commas and special characters are not allowed";
  }

  return null;
};
