import { createSlice } from "@reduxjs/toolkit";

import { AppThunk } from "../..";
import CustomSnackbarSettings from "../../Models/customSnackbarSettings";
import { Severity } from "../../Types/snackbarSeverityType";
import { RootState } from "../reducer";

const slice = createSlice({
  name: "snackbar",
  initialState: {
    open: false,
    severity: "success" as Severity,
    message: "",
  },

  reducers: {
    updateSnackbar: (snackbar, action) => {
      snackbar.open = action.payload.open;
      snackbar.severity = action.payload.severity;
      snackbar.message = action.payload.message;
    },
    clearSnackBar: (snackbar) => {
      snackbar.open = false;
      snackbar.message = "";
    },
  },
});

// Selectors
export const getSnackbarSettings = (state: RootState) =>
  state.entities.snackbar;

// Actions
const { updateSnackbar, clearSnackBar } = slice.actions;

// Action Creators
export const setSnackbarSettings =
  (snackbar: CustomSnackbarSettings): AppThunk =>
  (dispatch) =>
    dispatch(updateSnackbar(snackbar));

export const resetSnackBar = (): AppThunk => (dispatch) =>
  dispatch(clearSnackBar());

export default slice.reducer;
