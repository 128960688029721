import React from "react";
import { useSelector } from "react-redux";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CardContent, Grid, Typography } from "@mui/material";

import {
  rdx_getLoginSiteURL,
  rdx_getUsername,
} from "../../../Store/Slices/tenantRegistationSlice";

const Success = () => {
  // From Redux
  const rdx_username = useSelector(rdx_getUsername);
  const rdx_loginSiteURL = useSelector(rdx_getLoginSiteURL);

  return (
    <CardContent>
      <Typography color="secondary" my={3}>
        <FontAwesomeIcon icon={solid("circle-check")} size="5x" />
      </Typography>
      <Typography>You have successfully registered your account</Typography>

      <Typography variant="h6" mt={4}>
        Your username is:
      </Typography>

      <Typography variant="h5" mb={4} sx={{ fontWeight: "bold" }}>
        {rdx_username}
      </Typography>

      <Typography color="primary" display="inline">
        <FontAwesomeIcon icon={solid("circle-info")} />
      </Typography>
      <Typography
        sx={{ fontSize: 14, fontStyle: "italic" }}
        color="text.secondary"
        display="inline"
      >
        &nbsp; Please be sure to use your new password upon logging in
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        mt={3}
        mb={3}
      >
        <Button
          variant="contained"
          href={rdx_loginSiteURL}
          color="secondary"
          size="large"
        >
          Login
        </Button>
      </Grid>
    </CardContent>
  );
};

export default Success;
