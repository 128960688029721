import { configureStore as configure } from "@reduxjs/toolkit";

import serviceMiddleware from "./Middleware/serviceMiddleware";
import reducer from "./reducer";

const configureStore = () =>
  configure({
    reducer,
    //TODO: Add devTools in Production
    // devTools: false,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore these action types
          ignoredActions: ["service/callBegan"],
        },
      }).concat(serviceMiddleware),
  });

export default configureStore;
