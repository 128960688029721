import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import "./App.scss";
import CustomSnackbar from "./Components/HelperComponents/CustomSnackbars/CustomSnackbar";
import { isEmptyOrWhiteSpace } from "./Helpers/stringHelper";
import CreateRoutes from "./routes";
import { rdx_initializeStepManager } from "./Store/Slices/stepManagerSlice";
import {
  rdx_getFaviconURL,
  rdx_getIsInitialized,
  rdx_getNewToken,
  rdx_getRegistrationTypeID,
  rdx_loadTenantRegistration,
} from "./Store/Slices/tenantRegistationSlice";
import getTheme from "./Themes/ThemeFactory";

function App() {
  // From Redux
  const dispatch = useDispatch();
  const rdx_faviconURL = useSelector(rdx_getFaviconURL);
  const rdx_registrationTypeID = useSelector(rdx_getRegistrationTypeID);
  const rdx_newToken = useSelector(rdx_getNewToken);
  const rdx_isInitialized = useSelector(rdx_getIsInitialized);

  let [searchParams] = useSearchParams();

  let token = searchParams.get("p");

  useEffect(() => {
    if (!isEmptyOrWhiteSpace(token)) {
      dispatch(rdx_loadTenantRegistration(token));
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (!isEmptyOrWhiteSpace(rdx_newToken) && rdx_isInitialized) {
      dispatch(rdx_initializeStepManager());
    }
  }, [rdx_isInitialized, rdx_newToken, dispatch]);

  let theme = getTheme(rdx_registrationTypeID);

  document.getElementById("favicon").setAttribute("href", rdx_faviconURL);

  //
  // Add Google Analytics to the page if it's in a production environment.
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    document.getElementById("analytics").innerHTML = `
    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-34275957-18"></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', 'UA-34275957-18');
    </script>`;
  }

  useEffect(() => {
    if (window.location.pathname === "/") {
      window.location.href = window.location.href.includes(
        "register.wyseutilities.com"
      )
        ? "https://wyseutilities.com/"
        : "https://utilitiesinfo.conservice.com/";
    }
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <CreateRoutes />
        <CustomSnackbar />
      </ThemeProvider>
    </div>
  );
}

export default App;
